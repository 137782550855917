<template>
	<div class="produce">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/kong.png" >
			<!-- <search :navHttp="httpArr"></search> -->
			<div class="search">
				<!-- 搜索框 -->
				<van-search v-model="searchInput" shape="round" left-icon="../" placeholder="你想找什么？" class="ss" background="#fff" @keydown="show($event)" />
			</div>
		</div>
		
		<!-- 分类筛选 -->
		<div class="screen">
			<van-dropdown-menu active-color="#cc6633">
			  <van-dropdown-item title="全部分类" ref="item">
			   <van-cell class="allCart">
			     <template #right-icon>
					 <div style="width: 100%;">
						<div class="caliber">
							<div class="caliberlist" style="width: 2.1rem;justify-content: space-between;margin:0.09rem;" v-for="(item,index) in allList" @click="cartChange(index,item.id)" :class="{active:index===cartIndex}" :key='index'>{{item.name}}</div>
						</div>	 
					 </div>
					<div>
					  <button block rou @click="onReset">重置</button>
					  <button block round @click="onConfirm">确认</button>
					</div>
			     </template>
				 
			   </van-cell>
			</van-dropdown-item>
			  
			   <van-dropdown-item title="筛选" ref="item1">
			     <van-cell>
			       <template #right-icon>
						<div class="caliber">
							<p>口径范围</p>
							<div v-for="(item,index) in typeList.caliber" :key="index" :class="{active:index===navBool[0]}" @click="navTab(0,index,item.name,'caliber')">{{item.name}}</div>
						</div>
			       </template>
			     </van-cell>
			     <van-cell>
			       <template #right-icon>
						<div class="caliber">
							<p>压力等级</p>
							<div 
							v-for="(item,index) in typeList.pressure" :key="index" :class="{active:index===navBool[1]}" @click="navTab(1,index,item.name,'pressure')"
							>{{item.name}}</div>
						</div>
			       </template>
			     </van-cell>
				 <van-cell>
				   <template #right-icon>
						<div class="caliber">
							<p>主体材料</p>
							<div
							v-for="(item,index) in typeList.subject" :key="index" :class="{active:index===navBool[2]}" @click="navTab(2,index,item.name,'subject')" 
							>{{item.name}}</div>
						</div>
				   </template>
				 </van-cell>
				 <van-cell>
				   <template #right-icon>
						<div class="caliber">
							<p>连接方式</p>
							
							<div v-for="(item,index) in typeList.link" :key="index" :class="{active:index===navBool[3]}" @click="navTab(3,index,item.name,'link')">{{item.name}}</div>
						</div>
				   </template>
				 </van-cell>
				 <van-cell>
				   <template #right-icon>
						<div class="caliber">
							<p>操作方式</p>
							<div 
							v-for="(item,index) in typeList.do" :class="{active:index===navBool[4]}" @click="navTab(4,index,item.name,'do')" :key='item.id'
							>{{item.name}}</div>
						</div>
				   </template>
				 </van-cell>
				 <van-cell style="margin-bottom: 1.2rem;">
				   <template #right-icon>
						<div class="caliber">
							<p>应用行业</p>
							<div 
							v-for="(item,index) in typeList.app" :key="index" :class="{active:index===navBool[5]}" @click="navTab(5,index,item.name,'app')" 
							>{{item.name}}</div>
						</div>
				   </template>
				 </van-cell>
			     <div class="btn">
				   <button type="submit" block rou @click="onReset1">重置</button>
				   <button block round @click="onConfirm1">确认</button>
			     </div>
			   </van-dropdown-item>
			</van-dropdown-menu>
		</div>
		
		<!-- 产品中心产品列表 -->
		<div v-if="list.length<=0" style="color: #999;line-height: 30px;text-align: center;margin-top: 30px;">
			暂无产品信息
		</div>
		<div class="prolist">
			<div>
				<router-link v-for="(item,index) in list" :key="index" :to="{path:'/prodsc',query:{id:item.id}}" tag="div" class="item" >
					<img :src="item.image">
					<span style="margin-top: 0.02rem;">{{item.name}}</span>
					<!-- <span>{{item.product}}</span> -->
				</router-link>
			</div>
			<!-- 加载更多 -->
			<button class="honor-btn" @click="more">加载更多</button>
			<Free></Free>
		</div>
		<Foot class="bottomFooter"></Foot>
		<div style="height: 0.01rem;background:#242424;"></div>
	</div>
</template>

<script>
import Free from '../components/Free.vue'
import Foot from '../components/Foot.vue'
import Nav from '../components/Nav.vue'
import search from '../components/search.vue'
import $ from 'jquery'
export default{
	data(){
		return{
			value: 0,
			product:[],// 产品分类
			cartIndex:false,// 全部筛选的索引
			caliber:[],// 口径范围
			typeList:'',//分类
			productsList:[],// 商品
			productsList1:[],// 
			allList:[],// 全部分类
			pressure:[],// 压力等级
			res:[],
			subject/*主体材料*/:[],
			link/*连接方式*/:[],
			doing/* 操作方式 */:[],
			app:[],// 应用行业
			navBool:[false,false,false,false,false,false],//分类选中
			httpArr:{},//请求参数对象

			list:[],
			searchInput:'',
			page:1,
			next:true,
		}
	},
	components:{
		Free,
		Foot,
		Nav,
		search
	},
	created() {
		var _this = this
		this.getProducts();
		this.getAlltype();
		if(this.$route.query.keyStr){
			this.searchInput = this.$route.query.keyStr
		}
		_this.$apis.get(_this.$apis.inside_banner).then(res=>{
			console.log(res.data,'banner')
			$('.banner>img').attr('src',res.data.data.mobileProduct)
		})
	},
	methods:{
		//搜索 键盘事件
		show(e){
			if(e.keyCode == 13){
				console.log("键盘事件")
				this.list=[]
				this.getProductsList()
			}
		},
		getAlltype(){ //左边分类
			var _this = this
			_this.$apis.get(_this.$apis.allType)
			.then(res=>{
			  if(res.data.code == 1){
				_this.allList = res.data.data
				if(this.$route.query.categoryid){
					for(let i=0;i<res.data.data.length;i++){
						if(this.$route.query.categoryid == res.data.data[i].id){
							console.log(i)
						}
					}
					this.typeId = this.$route.query.categoryid
					this.getProductsList();
				}else{
					// _this.typeId = res.data.data[0].id
					_this.typeId = ''
					_this.getProductsList()
				}
			  }
			})
		},
		getProducts(){//右边筛选
			this.$http.get('https://admin.ruikefamen.com/api/products/term_list')
			.then(res=>{
				this.typeList = res.data.data
			})
		},
		navTab(type,index,value,key){
			if(this.navBool[type] === index){
				this.navBool[type] = false
			}else{
				this.navBool[type] = index
			}
			this.list = []
			this.httpArr[key] = value
			this.$forceUpdate();
			this.producList(this.httpArr)
			sessionStorage.setItem('secah',JSON.stringify(this.httpArr))
		},
		producList(data){
			this.$http.get('https://admin.ruikefamen.com/api/products/list',
			{params:data}).then(res=>{
			  if(res.data.code == 1){
				this.productsList = res.data.data.data
				this.productsList1 = res.data.data.data
				this.res = res.data.data
			  }
			})
		},
		getProductsList(){// 产品列表
			let data = this.httpArr
			data['keyword'] = this.searchInput
			data['categoryid'] = this.typeId
			data['page'] = this.page
			this.$http.get('https://admin.ruikefamen.com/api/products/list',{params:data}).then(res=>{
			  if(res.data.code == 1){
				if(res.data.data.data.length>0){
					this.next = true
					this.list = this.list.concat(res.data.data.data)
				}else{
					this.next = false
				}
			  }
			})
		},
		more(){// 加载更多
			if(this.next){
				this.page+=1
				this.getProductsList()
			}
		},
		onConfirm() {
			this.$refs.item.toggle();
		},
		onConfirm1() { //右边确定
			this.$refs.item1.toggle();
			this.getProductsList()
		},
		cartChange(index,id){//切换左边重置数据
			this.cartIndex = index
			this.typeId = id
			this.list = []
			this.page = 1
			this.next = true
			this.navBool=[false,false,false,false,false,false]
			this.httpArr={}
			this.getProductsList()
		},
		// 重置全部分类
		onReset(){
			this.cartIndex = false
			this.typeId = ''
			this.getProductsList()
		},
		// 重置筛选分类
		onReset1(){
			this.navBool = [false,false,false,false,false,false]
			this.httpArr = {}
			sessionStorage.setItem('secah',JSON.stringify(this.httpArr))
			sessionStorage.removeItem("value");
			this.getProductsList()
		},
	}
}
</script>

<style scoped="scoped" lang="less">
	.banner{
		width: 100%;
		margin-top: 1.18rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	
	// 分类筛选
	.screen{
		width: 100%;
		height: 1.7rem;
		margin-top: -0.2rem;
		background-color: #eee;
		padding-top: 0.88rem;
		/deep/ .van-dropdown-menu__bar{
			background-color: #eee;
			box-shadow: none;
			// margin-top: 1rem;
		}
		// 打开筛选时下拉框的长度
		/deep/ .van-dropdown-item__content {
			max-height:100%
		}

		.van-dropdown-item{
			width: 100%;
			.allCart{
				position: relative;
				width: 100%;
				height: 3.6rem;
				button{
					position: absolute;
					bottom: 0;
					height: 1.1rem;
					border: none;
					font-size: 0.3rem;
				}
				button:nth-child(1){
					left: 0;
					width: 50%;
					background-color: e7e7e7;
				}
				button:nth-child(2){
					right: 0;
					width: 50%;
					background-color: #cc6633;
					color: #fff;
				}
			}
			.caliber{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				p{
					width: 100%;
					font-size: 0.36rem;
					color: #333;
					margin: 0.25rem 0;
				}
				>div{
					padding: 0 0.3rem;
					height: 0.75rem;
					margin-top: 0.15rem;
					background-color: #fff;
					line-height: 0.75rem;
					text-align: center;
					font-size: 0.28rem;
					color: #333;
					border-radius: 5px;
					border: 1px solid #e3e3e3;
				}
				>div:nth-child(2n){
					margin-left: 0.1rem;
					margin-right: 0.1rem;
				}
				.active{
					background-color: #1d5381;
					color: #fff;
				}
			}
			
			
		}
		.btn{
			width: 100%;
			position: fixed;
			bottom: 0;
			button{
				width: 50%;
				height: 1.2rem;
				border: none;
				font-size: 0.3rem;
				color: #FFFFFF;
				background-color: #cc6633;
			}
			button:nth-child(1){
				background-color: #e7e7e7;
				color: #666;
			}
			
		}
		
	}
	
	// 产品列表
	.prolist{
		width: 100%;
		&>div:nth-child(1){
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid #E6E6E6;
		}
		.item{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 50%;
			height: 3.75rem;
			border-right: 1px solid #E6E6E6;
			border-top: 1px solid #E6E6E6;
			img{
				height: 2.9rem;
				width: auto;
			}
			span{
				padding: 0 0.2rem;
				font-size: 0.28rem;
				color: #333;
				text-align: center;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}
		.item:nth-child(2n){
			border-right: none;
		}
		// 加载更多按钮
		.honor-btn{
			display: block;
			width: 5.6rem;
			height: 1rem;
			background-color: #cc6633;
			border-radius: 0.5rem;
			border: none;
			color: #fff;
			font-size: 0.36rem;
			margin: 0.5rem auto;
			margin-bottom: 1rem;
		}
	}
	.search{
		position: relative;
		.ss::after{
			content: '';
			width: 0.35rem;
			height: 0.41rem;
			background-image: url(../assets/images/sousuo.png);
			background-size: 100% 100%;
			position: absolute;
			top: 50%;
			left: 0.2rem;
			transform: translateY(-50%);
		}
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.96rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: rgba(0,0,0,0);
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
		.van-search__content{
			background: #fff;
		}
	}
</style>
