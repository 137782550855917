<template>
	<div class="prodsc">
		<Nav></Nav>
		<!-- 轮播图 -->
		<van-swipe class="my-swipe" :autoplay="30000" @change="onChange" indicator-color="#cc6633">
			<van-swipe-item v-if="detail.video_file">
				<div class="videoBox" ref="vadio" @click="vadioClick()">
					<video v-if="mobile=='android'" id="video"
					width="100%"
					height="100%"
					x5-video-player-fullscreen="true"
					x5-playsinline
					playsinline
					webkit-playsinline
					preload="auto"
					src="">
					</video>
					<video v-if="mobile=='iPhone'" id="video"
						width="100%"
						height="100%"
						src="">
					</video>
					<div v-show="show" @click="play" style="color: #fff;" class="play mask flex_center"> 
						<img  class="playBtn " src="../assets/images/play.png"/>
					</div>
				</div>

			</van-swipe-item>
		  <van-swipe-item v-for="(item,index) in detail.images" :key="index">
			  <img class="swiperImage" :src="detail.images[index]" >
		  </van-swipe-item>
		</van-swipe>
		<div class="custom-indicator">{{ current + 1 }}/{{detail.images.length + 1}}</div>
		
		<!-- 产品参数 -->
		<div class="chanpcans">
			<p @click="vadio111()">{{detail.name}}</p>
			<div class="bbox">
				<div>
					<p>{{detail.caliber}}</p>
					<p>口径范围</p>
				</div>
				<div>
					<p>{{detail.pressure}}</p>
					<p>压力等级</p>
				</div>
				<div>
					<p>{{detail.hot}}</p>
					<p>工作温度</p>
				</div>
			</div>
			<div class="txt">
				<p><span>【产品型号】</span>{{detail.product}}</p>
				<p><span>【主体材料】</span>{{detail.subject}}</p>
				<p><span>【连接方式】</span>{{detail.link}}</p>
				<p><span>【操作方式】</span>{{detail.do}}</p>
				<p><span>【应用行业】</span>{{detail.app}}</p>	
			</div>
		</div>
		
		<!-- 产品详情 -->
		<div class="xiangq">
			<p class="title">产品详情</p>
			
			<div class="xiangq-pic">
				<p v-html="detail.content">{{detail.content}}</p>
			</div>
			
			<!-- 上一篇下一篇 -->
			<div class="nextprev" v-if="detail">
				<div tag="div" class="next" @click="qiehuan(detail.up.id)">
					<span>上一个产品：</span>{{detail.up?detail.up.name:'没有更多了'}}
				</div>
				<div tag="div"  class="prev" @click="qiehuan(detail.down.id)">
					<span>下一个产品：</span>{{detail.down?detail.down.name:'没有更多了'}}
				</div>
			</div>
			
		</div>
		
		<!-- 推荐产品 -->
		<div class="tuijian">
			<p class="title">推荐产品</p>
			<div class="tuijian-pro">
				<div class="item" v-for="(item,index) in hotList" :key="index" @click="qiehuan(item.id)">
					<img :src="item.image" >
					<span>{{item.name}}</span>
				</div>
			</div>
			
		</div>
		
		<!-- 获取报价 -->
		<div class="huoqu" style="z-index: 10;">
			<div class="huoqu-l" @click="goScroll">
				<img src="../assets/images/orderrr.png" alt="">
				<span>立即获取报价</span>
			</div>
			<div class="huoqu-r">
				<img src="../assets/images/tellll.png" alt="">
				<p>
					<a href="tel:+0371-27565515">
						咨询热线：<br><span>0371-27565515</span>
					</a>
				</p>
			</div>
		</div>
		
		<Free class="dididi"></Free>
		<Foot></Foot>
		<div style="height: 0.01rem;background:#242424;"></div>
	</div>
</template>

<script>
import Free from '../components/Free.vue'
import Foot from '../components/Foot.vue'
import Nav from '../components/Nav.vue'
import search from '../components/search.vue'
import advantage from '../components/advantage.vue'
import $ from 'jquery'
export default{
	data(){
		return {
			current: 0,
			detail:'',// 产品详情
			hotList:[],// 推荐商品

			show:true,
			mobile:"",
			text:"",

			changeUrl:'',
		}
	},
	components:{
		Free,
		Foot,
		Nav,
		search,
		advantage
	},
	computed:{
		vadioUrl(){
			return this.detail.video_file;
		}
	},
	mounted(){
		var that = this
   		let video = document.getElementById('video')
	},
	created() {
		this.text=navigator.appVersion
		this.mobile = navigator.appVersion.indexOf('iPhone') !== -1 ? 'iPhone' :  'android' 
		this.getDetail();
		this.getHot();
	},
	methods: {
		vadio111(){
			
		},
		play(){
			video.play();
			this.show = false
		},
		vadioClick(){
			var vadio = document.getElementById('video');
			if(vadio.paused){
				vadio.play()
				this.show = false
			}else{
				vadio.pause()
				this.show = true
			}
		},
		goScroll(){
			var position = $(".dididi").offset();
			position.top = position.top-58;
			$("html,body").animate({scrollTop:position.top}, 500);
		},
		qiehuan(id){
			this.$http.get('https://admin.ruikefamen.com/api/products/detail',
				{params:{id:id}})
			.then(res=>{
				this.detail = res.data.data
				this.changeUrl = res.data.data.video_file
			})
			$("html,body").animate({"scrollTop":"0"},500)
		},
	    onChange(index) {
	      	this.current = index;
		  	if(index != 0){
			  	var vadio = document.getElementById('video');
				vadio.pause()
				this.show = true
			}
	    },
		getDetail(){// 详情
			this.$http.get('https://admin.ruikefamen.com/api/products/detail',
				{params:{id:this.$route.query.id}})
			.then(res=>{
				if(res.data.code == 1){
					this.detail = res.data.data
					setTimeout(function(){
						var vadio = document.getElementById('video');
						vadio.src = res.data.data.video_file
					},10)
				}
			})
		},
		getHot(){
			this.$http.get('https://admin.ruikefamen.com/api/products/hot_lists')
			.then(res=>{
				this.hotList = res.data.data
			})
		}
	},
}	
</script>

<style scoped="scoped" lang="less">
.pages{
	margin-bottom: 1.4rem;
}
	// 轮播图的右下角
	 .custom-indicator {
	    position: absolute;
		top: 6.2rem;
	    right: 0.2rem;
	    padding: 0.02rem 0.05rem;
	    font-size: 0.12rem;
		border-radius: 0.2rem;
		width: 36px;
		text-align: center;
	    background: #b1b1b1;
		color: #fff;
	  }
	.van-swipe{
		width: 100%;
		background-color: #fff;
		margin-top: 1.2rem;
		text-align: center;
		border-bottom: 0.01rem solid #eee;
		img{
			display: block;
			width: 100%;
			height: auto;
		}
	}
	// 轮播图的小点
	/deep/ .van-swipe__indicator{
		background-color: #666;
	}
	
	// 产品参数
	.chanpcans{
		width: 100%;
		>p{
			font-size: 0.4rem;
			color: #333;
			padding: 0 0.3rem;
			line-height: 1.6rem;
		}
		.bbox{
			height: 1.3rem;
			border-top:1px solid #d6d6d6 ;
			border-bottom:1px solid #d6d6d6 ;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex-wrap: wrap;
			div{
				width: 33.33%;
				height: 0.72rem;
				border-left: 1px solid #d6d6d6;
				p:nth-child(1){
					font-weight: 900;
					font-size: 0.24rem;
					color: #1D5381;
				}
				p:nth-child(2){
					font-size: 0.28rem;
					color: #D0602D;
				}
				
			}
			div:nth-child(1){
				border-left: none;
			}
		}
		.txt{
			width: 100%;
			padding: 0.5rem 0.2rem;
			font-size: 0.28rem;
			color: #666;
			p{
				line-height: 0.56rem;
				span{
					color: #333;
				}	
			}
			
		}
	}
	
	// 产品详情
	.xiangq{
		width: 100%;
		.title{
			font-size: 0.36rem;
			color: #333;
			height: 0.9rem;
			background-color: #f7f7f7;
			text-align: center;
			line-height: 0.9rem;
			border-bottom: 2px solid #cc6633;
		}
		.xiangq-pic{
			padding: 0 0.3rem;
			padding-bottom: 0.28rem;
			border-bottom:1px solid #d6d6d6 ;
			img{
				width: 100%;
				height: auto;
			}
			p{
				color: #333;
				font-size: 0.28rem;
				padding: 0.2rem 0;
				line-height: 0.48rem;
			}
		}
		.nextprev{
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 2.4rem;
			margin-top: 0.2rem;
			div{
				font-size: 0.28rem;
				width: 100%;			
				padding: 0 0.3rem;
				border-bottom:1px solid #d6d6d6 ;
				border-top:1px solid #d6d6d6 ;
				height: 1rem;
				line-height: 1rem;
				color: #333;
				span{
					color: #999;
				}
			}
		}
	}
	
	// 推荐产品
	.tuijian{
		.title{
			font-size: 0.36rem;
			color: #333;
			margin-top: 0.42rem;
			height: 0.9rem;
			text-align: center;
			line-height: 0.9rem;
			border-bottom: 2px solid #cc6633;
		}
		.tuijian-pro{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 1rem;
			border-bottom: 1px solid #d6d6d6;
			.item{
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 3.8rem;
				width: 50%;
				border-top: 1px solid #d6d6d6;
				border-right: 1px solid #d6d6d6;
				padding-bottom: 0.5rem;
				span{
					padding: 0 0.2rem;
					font-size: 0.28rem;
					color: #333;
					text-align: center;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				img{
					height: 2.9rem;
					width: auto;
				}
			}
			.item:nth-child(2n){
				margin-right: none;
			}
		}
	}
	
	// 获取报价
	.huoqu{
		position: fixed;
		bottom: 0;
		height: 1.4rem;
		width: 100%;
		background-color: pink;
		display: flex;
		.huoqu-l,.huoqu-r{
			width: 50%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			img{
				vertical-align: middle;
				width: auto;
				height: 0.56rem;
				margin-right: 0.15rem;
			}
		}
		.huoqu-l{
			background-color: #cc6633;
			font-size: 0.36rem;
			color: #fff;
		}
		.huoqu-r{
			background-color: #e6e6e6;
			display: flex;
			p{
				color: #194e7a;
				font-size: 0.22rem;
				span{
					font-size: 0.3rem;
					font-weight: 900;
				}	
				a {
					text-decoration: none; /* 去除默认的下划线 */
					outline: none;	/* 去除旧版浏览器的点击后的外虚线框 */
					color: #194E7A;	/* 去除默认的颜色和点击后变化的颜色 */ 
				}
			}
			
		}
	}
	.van-swipe-item{
		height: 6.73rem;
	}
     .videoBox{
       position: relative;
       width: 100%;
	   height: 100%;
	   background: #000;
       .play{
         position: absolute;
         top: 50%;
         left: 50%;
		 transform: translate(-50%,-50%);
       }
     }
   .playBtn{
       width: 0.6rem !important;
	   height: auto !important;
   }
</style>
