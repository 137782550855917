<template>
	<div id="free">
		<div class="free-title ">
			<div class="wow animate__animated animate__slideInUp animate__delay-0.5s">
				<p>免费获取</p>
				<p>产品报价</p>	
			</div>
			<p class="wow  animate__animated animate__slideInUp animate__delay-0.5s" style="color: #999;">FREE PRODUCT QUOTATION</p>
			<p class="wow animate__animated animate__slideInUp animate__delay-0.5s">留下您的联系方式，我们会及时与您联系</p>
		</div>
		
		<div class="free-table wow animate__animated animate__slideInUp animate__delay-0.5s">
				<van-field
					class="input"
					v-model="username"
					name="您的称呼"
					placeholder="您的称呼"
				/>
				<van-field
					class="input1"
					v-model="tel"
					name="您的电话"
					placeholder="您的电话"
				/>
			<div class="free-textarea">
				<textarea placeholder="留言内容" v-model="desc"></textarea>
			</div>
			<div style="margin: 16px;">
			  <van-button round block native-type="submit" class="submitbtn" @click='getCommit()'>提交</van-button>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs' 
	export default{
		data(){
			return {
				username: '',
				tel: '',
				desc:'',
			}
		},
		methods: {
		    getCommit(values){
		    	var _this = this;
		    	_this.$apis.post(_this.$apis.commit,{
		    		name:_this.username,
		    		phone:_this.tel,
		    		content:_this.desc,
		    	}).then(function(res) {
					console.log(res)
					if(res.data.code == 1 ){
						console.log(_this)
						_this.$Notify({ type: 'warning', message: '留言成功' });
						_this.username='';
						_this.tel='';
						_this.desc='';
						
					}else{
						_this.$Notify({ type: 'danger', message: res.data.msg });
					}
		    	  
		    	})
		    },
		},
		mounted(){
			new WOW.WOW().init()
		}
	}
</script>

<style scoped="scoped" lang="less">
	#free{
		position: relative;
		width: 100%;
		height: 8.7rem;
		.free-title{
			height: 3.95rem;
			background: url(../assets/images/freebg.png) no-repeat center center /contain;
			padding: 0.6rem 0 0.5rem 0;
			text-align: center;
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #fff;
				}
			}
			>p:nth-child(2){
				color: #666;
				font-size: 0.24rem;
				margin: 0.05rem 0;
			}
			>p:nth-child(3){
				color: #fff;
				font-size: 0.24rem;
			}
		}
		.free-table{
			width: 6.96rem;
			height: 6.5rem;
			margin: 0 auto;
			background-color: #fff;
			position: absolute;
			top: 2.2rem;
			left: 0;
			right: 0;
			margin: 0 auto;
			border-radius: 5px;
			.van-form{
				display: flex;
				padding: 0.32rem;
				justify-content: space-between;
				.van-field{
					width: 48%;
					border-radius: 5px;
					border: 1px solid #d4d4d4;
				}
			}
			.free-textarea{
				width: 100%;
				padding:0 0.32rem;
				margin-top: -0.1rem;
				box-shadow: none !important;
				textarea{
					width: 100%;
					height: 2.8rem;
					padding: 0.17rem 0.38rem;
					border-radius: 5px;
					border: 1px solid #d4d4d4;
					resize: none;
				}
				// 更改提示框字体样式S
				textarea::-webkit-input-placeholder{color:#ccc;text-indent:0.5em;font-size: 0.26rem;padding-top: 0.1rem;}
			}
			.submitbtn{
				height: 0.96rem;
				width: 5.7rem;
				background-color: #cc6633;
				font-size: 0.36rem;
				color: #FFFFFF;
				margin: 0 auto;
			}
		}
	}
	.input{
		text-align: left;
		display: inline-block;
		text-align: left;
		margin-left: 0.32rem;
		margin-top: 0.36rem;
		margin-bottom: 0.18rem;
		width: 43%;
		border: 1px solid #cccccc;
		border-radius: 0.08rem;
		position: relative;
	}
	.input1{
		text-align: left;
		display: inline-block;
		margin-left: 0.32rem;
		margin-top: 0.36rem;
		margin-bottom: 0.18rem;
		width: 43%;
		border: 1px solid #cccccc;
		border-radius: 0.08rem;
		position: relative;
	}
	.input::after{
		content: '*';
		color: #CC6633;
		position: absolute;
		left: 0.17rem;
		top: 50%;
		transform: translateY(-50%);
		border: none;
	}
	.input1::after{
		content: '*';
		color: #CC6633;
		position: absolute;
		left: 0.17rem;
		top: 50%;
		transform: translateY(-50%);
		border: none;
	}
</style>
