<template>
  <baidu-map class="map" :center="{lng: 114.166681, lat: 34.398926}" :zoom="15" :scroll-wheel-zoom="false">
    <bm-marker :position="{lng: 114.172381, lat: 34.402244}" :dragging="true" @click="infoWindowOpen">
      <bm-info-window class="mapDiv" :show="show" @close="infoWindowClose" @open="infoWindowOpen">开封瑞科阀门有限公司</bm-info-window>
    </bm-marker>
  </baidu-map>
</template>

<style type="text/css">
	.map{
		width: 100%;
		height: 5.6rem;
	}
.mapDiv{
  width: 2.41rem;
  height: .55rem;
  line-height: .55rem;
  text-align: center;
  font-size: .18rem;
  font-weight: 700;
}
.BMap_bubble_content div:nth-child(1){
  padding: 0 !important;
}
#detailDiv{
  height: .35rem !important;
  margin-top: -.15rem;
}
.BMap_Marker{
  width: .10rem !important;
  height: .13rem !important;
  position: relative;
}
.BMap_Marker:nth-child(1)::after{
  content: '';
  display: block;
  width: 2.00rem;
  height: 1.20rem;
  white-space: nowrap;
  background: #f5f3f0;
  background-image: url(../assets/images/dingweishop.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  font-size: .12rem;
  position: absolute;
  left:-.85rem;
}






</style>
<script>
export default {
  data () {
    return {
      show: false
    }
  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    }
  }
}
</script>