<template>
	<div class="contactus">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/kong.png" >
			<div class="search">
				<!-- 搜索框 -->
				<van-search v-model="searchInput" shape="round" left-icon="../" placeholder="你想找什么？" class="ss" background="#fff" @keydown="show($event)" />
			</div>
		</div>
		
		<div class="con-main">
			<div class="con-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>联系</p>
					<p>我们</p>	
				</div>
				<p align="center">CONTACT US</p>
				<p align="center">您可以通过以下方式联系到我们</p>
			</div>
			
			<div class="con-cart wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div class="cart-t">
					<img src="../assets/images/logo-b.png" >
				</div>
				<div class="cart-b">
					<p>名称：开封瑞科阀门有限公司</p>
					<p>地址：{{contactList.steamAddress}}</p>
					<p>电话：<span>{{contactList.steamHotline}}</span></p>
					<p>传真：{{contactList.steamFax}}</p>
					<p>邮编：{{contactList.steamPostcode}}</p>
					<p>网址：{{contactList.steamUrl}}</p>
					<p>邮箱：{{contactList.steamEmail}}</p>
				</div>
			</div>
			
			<div class="xiaos-cart ">
				<div class="box" v-for="(item,index) in aboutus" :key="index">
					<p>{{aboutus[index].name}}</p>
					<p>电 话：<span>{{aboutus[index].telnumber}}</span></p>
					<p>手 机：<span>{{aboutus[index].phone}}</span></p>
					<p>传 真：<span>{{aboutus[index].fax}}</span></p>
					<p>邮 箱：<span>{{aboutus[index].email}}</span></p>
					<p>微 信：<span>{{aboutus[index].wechat}}</span></p>
				</div>
			</div>
		</div>
		
		<Map style="margin: 0.8rem 0 1rem 0;"></Map>
		<Foot></Foot>
		<div style="height: 0.01rem;background:#242424;"></div>
	</div>
</template>

<script>
import Free from '../components/Free.vue'
import Foot from '../components/Foot.vue'
import Nav from '../components/Nav.vue'
import search from '../components/search.vue'
import advantage from '../components/advantage.vue'
import Map from '../components/map.vue'
import WOW from 'wowjs' 
import $ from 'jquery'
export default{
	data(){
		return {
			aboutus:[],// 销售部
			contactList:[],// 联系我们
			searchInput:'',
		}
	},
	components:{
		Free,
		Foot,
		Nav,
		search,
		advantage,
		Map
	},
	created() {
		var _this = this
		this.getAboutus();
		this.getContact();
		_this.$apis.get(_this.$apis.inside_banner).then(res=>{
			console.log(res.data,'banner')
			$('.banner>img').attr('src',res.data.data.mobileCantact)
		})
	},
	methods:{
		show(e){
			if(e.keyCode == 13){
				this.$router.push({
					path:'/product',
					query:{keyStr:this.searchInput}
				})
			}
		},
		getAboutus(){
			this.$http.get("https://admin.ruikefamen.com/api/index/aboutus")
			.then(res=>{
				this.aboutus = res.data.data
			})
		},
		getContact(){
			this.$http.get("https://admin.ruikefamen.com/api/index/index")
			.then(res=>{
				this.contactList = res.data.data
			})
		}
	},
	mounted(){
		new WOW.WOW().init()
	},
}
</script>

<style scoped="scoped" lang="less">
	.banner{
		width: 100%;
		margin-top: 1.18rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	
	.con-main{
		width: 100%;
		padding: 0 0.3rem;
		background: url(../assets/images/bg3.png) no-repeat top center/ contain;
		// 联系我们标题
		.con-title{
			margin-top: 1rem;
			// height: 5rem;
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}
			}
			>p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
				margin-bottom: 0.15rem;
			}
		}	
		.con-cart{
			width: 100%;
			margin: 0.5rem 0 0.3rem 0;
			height: 5.85rem;
			background-color: #fff;
			border: 1px solid #ddeedd;
			padding: 0.42rem 0;
			.cart-t{
				text-align: center;
				img{
					height: 1rem;
					width: auto;
				}
			}
			.cart-b{
				margin-top: 0.4rem;
				color: #333;
				font-size: 0.28rem;
				line-height: 0.5rem;
				padding: 0 0.4rem;
				span{
					color: #cc6633;
					font-weight: 900;
					font-size: 0.36rem;
				}
			}
		}
		// 销售卡片
		.xiaos-cart{
			display: flex;
			flex-wrap: wrap;
			.box{
				background-color: #f5f5f5;
				padding: 0.3rem 0.14rem;
    			margin-right: 0.17rem;
				margin-bottom: 0.2rem;
				p{
					line-height: 0.42rem;
					font-size: 0.24rem;
					color: #333;
					span{
						color: #666;
					}
				}
				p:nth-child(1){
					font-size: 0.3rem;
				}
			}
			.box:nth-child(2n){
				margin-right: 0;
			}
		}
	
	}
	.search{
		position: relative;
		.ss::after{
			content: '';
			width: 0.35rem;
			height: 0.41rem;
			background-image: url(../assets/images/sousuo.png);
			background-size: 100% 100%;
			position: absolute;
			top: 50%;
			left: 0.2rem;
			transform: translateY(-50%);
		}
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.96rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: rgba(0,0,0,0);
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
		.van-search__content{
			background: #fff;
		}
	}
</style>
