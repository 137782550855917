<template>
	<div id="ss">
		<div class="search">
			<!-- 搜索框 -->
			<van-search v-model="value" @input="input" shape="round" placeholder="你想找什么？" class="ss" background="#F7F8FA" @keydown="show($event)" />
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				value:'',// 搜索内容
				product:[],// 产品分类
				caliber:[],// 口径范围
				productsList:[],// 商品
				productsList1:[],// 
				pressure:[],// 压力等级
				res:[],
				subject/*主体材料*/:[],
				link/*连接方式*/:[],
				doing/* 操作方式 */:[],
				app:[],// 应用行业
			}
		},
		created() {
			this.producList();
		},
		methods:{
			input(){
				sessionStorage.setItem('value',this.value)
			},
			show(e){
			   if(e.keyCode == 13){
				   let httpObg = (sessionStorage.getItem("secah"))
				   var obj = eval('(' + httpObg + ')');
				   if(this.$route.path == '/'){
						this.producList(obj);
						this.$router.push({path:'/product'})
						// console.log(sessionStorage.getItem("value"),'123')
				   }
				    if(this.$route.path == '/product'){
					   this.producList(obj)
					   this.$router.go(0);
				   }
				   if(this.$route.path == '/conpany'){
					   this.$router.push({path:'/product'})
					   this.producList(obj)
					   // this.$router.go(0);
				   }
			   }
			},
			producList(data){
				// data.keyword = this.value
				this.$http.get(this.$http.productsList,
				{params:data}).then(res=>{
				  if(res.data.code == 1){
					this.productsList = res.data.data.data
					this.productsList1 = res.data.data.data
					this.res = res.data.data
				  }
				})
			},
		}
	}
</script>

<style scoped="scoped" lang="less">
	.search{
		position: relative;
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.96rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: rgba(0,0,0,0);
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
	}
</style>
