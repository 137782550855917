// 引入vue 和 vuex
import Vue from 'vue';
import Vuex from 'vuex';
 
// 使用vuex插件,跟router一样
Vue.use(Vuex);             
 
// 直接导出 一个 Store 的实例
export default new Vuex.Store({
    state: {            
        Language: sessionStorage.getItem('changeLang') ? sessionStorage.getItem('changeLang') : 'cn',
    },
    getters: {
 
    },
    // 同步操作直接修改state里面的数据
    mutations: {
        setLanguage: (state, data) => {
            state.Language = data;
            sessionStorage.setItem('changeLang', data);
        },
    }
});