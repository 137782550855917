import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Conpany from '../views/Conpany.vue'
import Newsdsc from '../views/Newsdsc.vue'
import Patent from '../views/Patent.vue'
import Case from '../views/Case.vue'
import Service from '../views/Service.vue'
import Contactus from '../views/Contactus.vue'
import Prodsc from '../views/Prodsc.vue'
import Produce from '../views/Produce.vue'
Vue.use(VueRouter)

// 配置路由
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/conpany',
    name: 'Conpany',
    component: Conpany
  },
  {
    path: '/newsdsc',
    name: 'Newsdsc',
    component: Newsdsc
  },
  {
    path: '/patent',
    name: 'Patent',
    component: Patent
  },
  {
    path: '/case',
    name: 'Case',
    component: Case
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/contactus',
    name: 'Contactus',
    component: Contactus
  },
  {
    path: '/prodsc',
    name: 'Prodsc',
    component: Prodsc
  },
  {
    path: '/product',
    name: 'product',
    component: Produce
  },
  
  

]

const router = new VueRouter({
  mode: 'history', // 访问路径不带#号
  // base: '/mobel',  // 配置单页应用的基路径
  routes
})
router.beforeEach((to, from, next) => {
     // 让页面回到顶部
     document.documentElement.scrollTop = 0
     // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
 })
export default router
