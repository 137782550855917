<template>
	<div id="nav">
		<div class="nav">
		<!-- 导航 -->
			<div class="logo">
				<img src="../assets/images/LOGO.png">
			</div>
			<div class="oboxr">
				<img src="../assets/images/language.png" style="width: 1.58rem;height: 0.38rem;"  @click="showPopup1">
				<img src="../assets/images/heng.png" style="width: 0.42rem;height: 0.36rem;" @click="showPopup2" v-show="!showNav">
				<!-- 语言弹窗 -->
				<van-popup v-model="showlang" position="left" :style="{ height: '100%',width:'78%' }" class="lang">
					<ul>
						<li>选择网站语言</li>
						<div class="lange" v-for="(item,i) in languageArr" :key="i" @click="gogogo(item.url)">
							<img :src="item.image" alt="">
							<div>{{item.title}}</div>
						</div>



						<li>
							<img src="../assets/images/LOGO.png" >
							<p>用极致匠心为世界造好阀</p>
							<p>咨询热线：<span>0371-27565515</span></p>
						</li>
					</ul>
					
					
				</van-popup>
				
				
				<!-- 导航弹窗 -->
				<van-popup v-model="showNav" position="left" :style="{ height: '100%',width:'78%' }" class="shownav">
					<ul>
						<li>导航</li>
						<router-link to="/" tag="li">
							<p>首页</p>
							<van-icon name="arrow" />
						</router-link>
						<router-link to="/conpany" tag="li">
							<p>走进瑞科</p>
							<van-icon name="arrow" />
						</router-link>
						<router-link to="/product" tag="li">产品中心</router-link>
						<router-link to="/patent" tag="li"><p>资质专利</p><van-icon name="arrow" /></router-link>
						<router-link to="/case" tag="li"><p>工程案例</p><van-icon name="arrow" /></router-link>
						<router-link to="/service" tag="li">技术服务</router-link>
						<router-link to="/contactus" tag="li">联系我们</router-link>
						<li>
							<img src="../assets/images/LOGO.png" >
							<p>用极致匠心为世界造好阀</p>
							<p>咨询热线：<span>0371-27565515</span></p>
						</li>
					</ul>
				</van-popup>
				<img src="../assets/images/colse.png" alt="close" class="close" v-show="showNav" @click="showNav = !showNav">
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				showlang:false,// 语言弹框
				showNav: false,// 导航弹框
				languageArr:'',
			}
		},
		created(){
			this.getLanguage()
		},
		methods:{
			gogogo(url){
				window.location.href=url
			},
			getLanguage(){
				this.$http.get("https://admin.ruikefamen.com/api/index/index_language")
				.then(res=>{
					if(res.data.code == 1){
						this.languageArr = res.data.data
					}
				})
			},
			// 语言弹窗
			showPopup1() {
				this.showlang = true;
			},
			// 弹框导航
			showPopup2() {
				this.showNav = true;
			},
		}
	}
</script>

<style scoped="scoped" lang="less">
	.nav{
		width: 100%;
		height: 1.2rem;
		background-color: #1d5381;
		overflow: hidden;
		position: fixed;
		top: 0;
		z-index: 999;
		.logo img{
			/* 左边logo图 */
			width: 2.7rem;
			height: 0.6rem;
			margin-top: 0.3rem;
			margin-left: 0.3rem;
		}
		.oboxr{
			/* 右边橘色的方块 */
			position: absolute;
			width: 1.6rem;
			height: 100%;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			background: url(../assets/images/obox.png) no-repeat center left / cover;
			>img:nth-child(1){
				/* 语言 language */
				position: absolute;
				right: 1.6rem;
			}
			>img:nth-child(2){
				/* 面包屑导航 */
				position: absolute;
				right: 0.45rem;
			}
			// 关闭图标
			.close{
				position: fixed;
				right: 0.5rem;
				width: 0.4rem;
				height: 0.4rem;
				z-index: 9999;
			}
			.lang{
				background-color: #1a1a1a;
				ul{
					width: 100%;
					color: #ccc;
					padding-left: 0.2rem;
					font-size: 0.28rem;
					li{
						height: 1.12rem;
						line-height: 1.12rem;
						border-top: 1px solid #454545;
						display: flex;
						align-items: center;
						img{
							height: 0.36rem;
							width: auto;
						}
					}
					li:nth-child(1){
						color: #fff;
						font-size: 0.4rem;
						border-top: none;
					}
					li:last-child{
						display: block;
						color: #999;
						padding-top: 0.42rem;
						line-height: 0.34rem;
						img{
							width: 2.6rem;
							height: auto;
							margin-bottom: 0.1rem;
						}
						p:nth-child(3){
							margin-top: 0.3rem;
							span{
								font-weight: 900;
								font-size: 0.4rem;
							}
							color: #d3642c;
						}
					}
			}
		}
			.shownav{
				background-color: #1a1a1a;
				ul{
					width: 100%;
					color: #ccc;
					padding-left: 0.2rem;
					font-size: 0.28rem;
					// border-left: 3px solid #cc6633;
					li{
						height: 1.12rem;
						line-height: 1.12rem;
						border-top: 1px solid #454545;
						// background-color: pink;
					}
					li:nth-child(2),
					li:nth-child(3),
					li:nth-child(5),
					li:nth-child(6){
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-right: 0.25rem;
					}
					li:nth-child(1){
						color: #fff;
						font-size: 0.4rem;
						border-top: none;
					}
					li:last-child{
						color: #999;
						padding-top: 0.42rem;
						line-height: 0.34rem;
						img{
							width: 2.6rem;
							height: auto;
							margin-bottom: 0.1rem;
						}
						p:nth-child(3){
							margin-top: 0.3rem;
							span{
								font-weight: 900;
								font-size: 0.4rem;
							}
							color: #d3642c;
						}
					}
				}
			}
		}
	}
	.lange{
		height: 1rem;
		display: flex;
		align-items: center;
		border-top: 1px solid #454545;
		img{
			width: 0.6rem;
			margin-right: 0.15rem;
		}
	}
</style>
