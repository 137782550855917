<template>
	<div class="index">
		<Nav></Nav>
		
		<!-- 轮播图 -->
		<van-swipe class="swipe" :autoplay="3000" indicator-color="#cc6633" :show-indicators="true">
		  <van-swipe-item v-for="(item,index) in banner" :key="index">
			  	<img v-if="item.indexOf('.mp4')<0" :src="item" width="100%" height="100%">
				<div v-if="item.indexOf('.mp4')>0">
					<div class="videoBox" ref="vadio" @click="vadioClick()">
						<video v-if="mobile=='android'" id="video"
						loop="loop"
						width="100%"
						height="100%"
						autoplay="autoplay"
						muted="true"
						x5-video-player-fullscreen="true"
						x5-playsinline
						playsinline
						webkit-playsinline
						preload="auto"
						:src="item">
						</video>
						<video v-if="mobile=='iPhone'" id="video"
							loop="loop"
							autoplay="autoplay"
							muted="true"
							width="100%"
							height="100%"
							:src="item">
						</video>
						<div v-show="show" @click="play" style="color: #fff;" class="play mask flex_center"> 
							<img  class="playBtn " src="../assets/images/play.png"/>
						</div>
					</div>
				</div>
			</van-swipe-item>
 		</van-swipe>
		<div class="container">
			<!-- 主题内容区 -->
			<div class="search">
				<!-- 搜索框 -->
				<van-search v-model="searchInput" shape="round" left-icon="../assets/images/sousuo.png" placeholder="你想找什么？" class="ss" background="#F7F8FA" @keydown="show($event)" />
			</div>
			
			<!-- 功能区 6个块-->
			<div class="gongn" v-if="categoryPro">
				<router-link class="box" v-for="(item,index) in categoryPro" :key="index" :to="{path:'/product',query:{categoryid:categoryPro[index].id}}" >
					<img :src="item.mimage" >
					<p>{{item.name}}<br><span>{{item.nickname}}</span></p>
				</router-link>
				
				<router-link to='/product' class="box">
					<img src="../assets/images/666.png" >
					<p>全部产品<br><span>ALL VALVE</span></p>
				</router-link>
			</div>
		
			
			<!-- 走进瑞科 -->
			<div class="goruke">
				<!-- 标题 -->
				<div class="goruke-title wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<p>走进</p>
						<p>瑞科</p>	
					</div>
					<p align="center">ABOUT RUIKE</p>
				</div>
				
				<!-- 数据 -->
				<div class="goruke-dsc wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<p class="fontFZ">{{indexNum1}}<span>+</span></p>
						<p>阀门经验</p>
					</div>
					<div>
						<p class="fontFZ">{{indexNum2}}<span>+</span></p>
						<p>专利技术</p>
					</div>
					<div>
						<p class="fontFZ">{{indexNum3}}<span>+</span></p>
						<p>加工设备</p>
					</div>
					<div>
						<p class="fontFZ">{{indexNum4}}<span>+</span></p>
						<p>合作客户</p>
					</div>
					
				</div>
				
				<!-- 内容 -->
				<div class="ddddd">
					<p class="txt gorukeTitle gorukeTitle1 wow animate__animated animate__slideInUp animate__delay-0.5s" style="margin-top: 0.52rem;" v-if="contentDisplay" v-html="index.content">{{index.content}}</p>
					<p class="txt1 gorukeTitle gorukeTitle2 wow animate__animated animate__slideInUp animate__delay-0.5s" style="margin-top: 0.52rem;" v-if="!contentDisplay" v-html="index.content">{{index.content}}</p>
				</div>
				<!-- 查看更多 -->
				<button class="more moregoruke wow animate__animated animate__slideInUp animate__delay-0.5s" @click="chakanMore">{{contentDisplay?'收起':'查看更多'}}</button>
				
			</div>
			
			<!-- 推荐产品 -->
			<div class="tuijian" v-if="hotList">
				<div class="tuijian-title wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<p>推荐</p>
						<p>产品</p>	
					</div>
					<p align="center">RECOMMENDED PRODUCTS</p>
				</div>
				<!-- 商品列表 -->
				<div v-if="hotList" class="tuijian-list wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div class="item" v-for="(item,index) in hotList" :key="index" @click="goDetail(item.id)">
						<img :src="item.image" >
						<div>
							<p>{{item.name}}</p>
						</div>
					</div>
				</div>
				
				<button class="more wow animate__animated animate__slideInUp animate__delay-0.5s honor-btn" @click="more">全部产品</button>
			</div>
			
			<!-- 资质专利 -->
			<div class="patent">
				<!-- 标题 --> 
				<div class="patent-title wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<p>资质</p>
						<p>专利</p>	
					</div>
					<p align="center">QUALIFICATION PATENT</p>
				</div>
				
				<!-- 轮播图 -->
				<van-swipe v-if="patentlist" :loop="false" class="patent-swipe" :show-indicators='false'  ref="swiper">
				  <van-swipe-item v-for="(item,index) in patentlist" :key="index">
					  <img @click="showPupop(item.image)" :src="patentlist[index].image">
				  </van-swipe-item>
				</van-swipe>
				<img src="../assets/images/leftjiant.png" class="prev" @click="prevTap" style="width: 0.7rem;height: 1rem;">
				<img src="../assets/images/rightjiant.png"  @click="nextTap" class="next" style="width: 0.7rem;height: 1rem;">
			</div>
			
			<!-- 新闻资讯 -->
			<div class="news" v-if="news_list">
				<!-- 标题 -->
				<div class="news-title wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<p>新闻</p>
						<p>资讯</p>	
					</div>
					<p align="center">NEWS</p>
				</div>
				
				<div class="news-cart wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div v-for="(item,index) in news_list" :key="index" style="cursor: pointer;" @click="changeList(index)" :class="index==cartIndex?'item active':'item'"  >{{item.name}}</div>
				</div>
				
				
				<!-- 新闻列表 -->
				<div class="news-list wow animate__animated animate__slideInLeft animate__delay-0.5s" v-if="news_list.length>0">
					<img @click="goNewsDetail(news_list[cartIndex].newslist[0].id)" v-if="news_list[cartIndex].newslist.length>0" :src="news_list[cartIndex].newslist[0].eximage"  class="wow animate__animated animate__slideInUp animate__delay-0.5s" style="width: 100%;height: 100%;">
					<router-link v-for="(item,index) in news_list[cartIndex].newslist" :key="index" :to="{path:'/newsdsc',query:{id:item.id}}"  tag="div" class="item" >
						<div class="item-l">
							<p>{{item.dateTime[2]}}</p>
							<p>{{item.dateTime[0]}}.{{item.dateTime[1]}}</p>
						</div>
						<div class="item-r">
							<p class="ptitle">{{item.title}}</p>
							<div class="p2">
								<p v-html="item.content">{{item.content}}</p>
								<div class="xiangqing">
									<span> ... </span>
									<span>[详情]</span>
								</div>
							</div>
						</div>
					</router-link>
					<div v-if="news_list[cartIndex].newslist.length<=0" style="color: #999;text-align:center;line-height: 30px;">
						暂无新闻动态
					</div>
				</div>
			</div>
			
			<advantage></advantage>
			
			<!-- 工程案例 -->
			<div class="case" >
				<!-- 标题 -->
				<div class="case-title wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<p>工程</p>
						<p>案例</p>	
					</div>
					<p align="center">ENGINEERING CASE</p>
				</div>
				
				<div class="case-pic">
					<div class="pic1" v-if="casetype.length>0">
						<img :src="casetype[0].image" style="width: 100%;">
						<router-link :to="{path:'/case',query:{id:casetype[0].id}}" tag="div" class="txt wow animate__animated animate__slideInUp animate__delay-0.5s">
							<p>{{casetype[0].name}}</p>
							<p v-html="casetype[0].content">{{casetype[0].content}}</p>
						</router-link>
					</div>
					<div class="pic2 pic1" v-if="casetype.length>0">
						<div class="pic2-l">
							<img :src="casetype[1].image" >
							<router-link :to="{path:'/case',query:{id:casetype[1].id}}" tag="div" class="txt  wow animate__animated animate__slideInUp animate__delay-0.5s">
								<p>{{casetype[1].name}}</p>
								<p v-html="casetype[1].content">{{casetype[1].content}}</p>
							</router-link>
						</div>
						<div class="pic2-r">
							<router-link to="/case" tag="div" class="txt  wow animate__animated animate__slideInUp animate__delay-0.5s">
								<p class="position" style="margin-bottom: 0;">VIEW ALL CASES</p>
								<p style="bottom: 0;">查看全部案例</p>
								<button>MORE<span>&gt;</span></button>
							</router-link>
						</div>
					</div>
					
					<div class="pic2 pic1 pic3">
						<div class="pic2-l" v-if="casetype.length>0">
							<router-link :to="{path:'/case',query:{id:casetype[2].id}}" tag="div" class="txt  wow animate__animated animate__slideInUp animate__delay-0.5s">
								<p>{{casetype[2].name}}</p>
								<p v-html="casetype[2].content">{{casetype[2].content}}</p>
							</router-link>
						</div>
						<div class="pic2-r" v-if="casetype.length>0">
							<img :src="casetype[3].image" >
							<router-link :to="{path:'/case',query:{id:casetype[3].id}}" tag="div" class="txt wow animate__animated animate__slideInUp animate__delay-0.5s">
								<p>{{casetype[3].name}}</p>
								<p v-html="casetype[3].content">{{casetype[3].content}}</p>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="show" @click="showPupop('')">
			<img :src="showImage" alt="">
		</div>
		<Free style="margin-top: 1rem;"></Free>
		<Foot></Foot>
		<div style="height: 0.01rem;background:#242424;"></div>
	</div>
</template>
<script>
import Free from '../components/Free.vue'
import Foot from '../components/Foot.vue'
import Nav from '../components/Nav.vue'
import search from '../components/search.vue'
import advantage from '../components/advantage.vue'
import WOW from 'wowjs' 
import $ from 'jquery'
import axios from 'axios'
export default{
	data(){
		return {
			show:false,
			mobile:"",
			text:"",

			showImage:'',

			cartIndex:0,
			swipeId:0,
			newsIndex:0,
			experienceNum:0,// 阀门经验
			patentNum:0,// 专利技术
			equipmentNum:0,// 加工设备
			customersNum:0,// 合作客户
			index:[],// 首页数据  
			hotList:[],// 推荐产品
			news_list:[],
			banner:[],// banner图
			indexNum1:0,// 10
			indexNum2:0,// 30
			indexNum3:0,// 100
			indexNum4:0,// 2000
			numout:'',
			categoryPro:[],
			hotList1:4,
			patentlist:[],
			casetype:"",
			a:0,

			searchInput:'',
			page:1,
			playerOptions: {
				playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
				autoplay: true, // 如果true,浏览器准备好时开始回放。
				muted: true, // 默认情况下将会消除任何音频。
				loop: true, // 导致视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [{
				type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
				src: 'https://video.699pic.com/videos/21/22/43/b_WakrPRgmqbEM1629212243.mp4' // url地址
				}],
				poster: '', // 你的封面地址
				width: document.documentElement.clientWidth, // 播放器宽度
				notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
				timeDivider: true,
				durationDisplay: true,
				remainingTimeDisplay: false,
				fullscreenToggle: true // 全屏按钮
				}
			},
			contentDisplay:false,
		}
	},
	components:{
		Free,
		Foot,
		Nav,
		search,
		advantage
	},
	mounted(){
		var that = this
   		let video = document.getElementById('video')
	},
	created(){
		this.text=navigator.appVersion
		this.mobile = navigator.appVersion.indexOf('iPhone') !== -1 ? 'iPhone' :  'android' 
		this.getIndex();
		this.getHot();
		this.getBanner();
		this.getIndexNum();
	},
	methods:{
		play(){
			video.play();
			this.show = false
		},
		vadioClick(){
			var vadio = document.getElementById('video');
			if(vadio.paused){
				vadio.play()
				this.show = false
			}else{
				vadio.pause()
				this.show = true
			}
		},
		showPupop(url){
			console.log(url)
			this.showImage = url
			$('.show').fadeToggle()
		},
		//内容超过200个字符显示省略号
		richTextFormat(value) {
			value = value.replace(/<\/?.+?>/g,'')
			value = value.replace(/\s+/g,'')
			if (value.length > 200) {
				this.isLength = true;	
				return value.slice(0, 170) + "...";
			}else{
				this.isLength = false;
			}
			return value;
		},
		goNewsDetail(id){//进入第一个新闻
			this.$router.push({
				path:'/newsdsc',
				query:{id:id}
			})
		},
		goDetail(id){
			this.$router.push({
				path:'/prodsc',
				query:{id:id}
			})
		},
		show(e){
			if(e.keyCode == 13){
				this.$router.push({
					path:'/product',
					query:{keyStr:this.searchInput}
      			})
			}
		},
		toChineseNum(num){//数字单位换算
			let dtext=['','十','百','千','万']
			let len=num.toString()
			let numArr=num.toString().split('')
			let numTxt=''
			if(num>=10000){
				numTxt = len.substring(0,len.length - 4) + '万'
			}else{
				numTxt = len
			}
			return numTxt;
		},
		more(){// 推荐商品加载更多
			// if(this.next){
			// 	this.page+=1
			// 	this.getHot()
			// }
			this.$router.push({
				path:'/product'
			})
		},
		changeList(index){
			this.cartIndex = index
		},
		nextTap(){
			this.$refs.swiper.next()
		},
		prevTap(){
			this.$refs.swiper.prev()
		},
		getBanner(){
			var _this = this
				_this.$apis.get(_this.$apis.banner)
				.then(res=>{
			if(res.data.code == 1){
					_this.banner = res.data.data.mobileSteamImages
					setTimeout(function(){
						var vadio = document.getElementById('video');
						console.log(vadio.play(),'视频实例')
						vadio.play()
					},100)
				}
			})
		},
		getIndex(){
			var _this = this
			let regex = new RegExp('<img', 'gi')
			_this.$apis.get(_this.$apis.model)
			.then(res=>{
			  if(res.data.code == 1){
				  _this.index = res.data.data 
				  _this.index.content1 = _this.richTextFormat(_this.index.content)
				  console.log(res.data.data.news_list.length)
					for(let i=0;i<res.data.data.news_list.length;i++){
						for(let j=0;j<res.data.data.news_list[i].newslist.length;j++){
							console.log(res.data.data.news_list[i].newslist[j].createtime,'item')
							res.data.data.news_list[i].newslist[j].dateTime = res.data.data.news_list[i].newslist[j].createtime.split('-')
							res.data.data.news_list[i].newslist[j].content = res.data.data.news_list[i].newslist[j].content.replace(regex, `<img style="max-width: 100%;margin-top:10px"`)
						}
					}
					console.log(res.data.data.news_list,'新闻')
				  _this.news_list = res.data.data.news_list// 新闻资讯
				  _this.categoryPro = res.data.data.category_product
				  _this.patentlist = res.data.data.patent_list
				  _this.casetype = res.data.data.case_type
			  }
			})
		},
		getHot(){
			this.$http.get('https://admin.ruikefamen.com/api/products/hot_lists',{params:{page:this.page}})
			.then(res=>{
				if(res.data.data.length>0){
					this.next = true
					this.hotList = this.hotList.concat(res.data.data)
				}else{
					this.next = false
				}
				
			})
		},
		getIndexNum(){
			this.$http.get('https://admin.ruikefamen.com/api/index/index_data')
			.then(res=>{
				this.getTimeOut1(res.data.data.famenExp)
				this.getTimeOut2(res.data.data.Patented)
				this.getTimeOut3(res.data.data.Testing)
				this.getTimeOut4(res.data.data.Customers)
				
			})
		},
		getTimeOut1(num){
			var _this = this
			_this.numout1 = setInterval(function () {
				if(_this.indexNum1==num){
					clearTimeout(_this.numout1)
				}else{
					_this.indexNum1+=1
				}
			},20)
		},
		getTimeOut2(num){
			var _this = this
			_this.numout2 = setInterval(function () {
				if(_this.indexNum2==num){
					clearTimeout(_this.numout2)
				}else{
					_this.indexNum2+=1
				}
			},30)
		},
		getTimeOut3(num){
			var _this = this
			_this.numout3 = setInterval(function () {
				if(_this.indexNum3==num){
					clearTimeout(_this.numout3)
				}else{
					_this.indexNum3+=5
				}
			},30)
		},
		getTimeOut4(num){
			var _this = this
			_this.indexNum4 = _this.toChineseNum(num)
		},
		chakanMore(){// 走进瑞科查看更多
			console.log(1)
			this.contentDisplay = !this.contentDisplay
		}
	},
	mounted(){
		new WOW.WOW().init();
		
	}
}
 
</script>

<style lang="less" scoped="scoped">
.show{
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
	img{
		width: 80%;height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}
.vjs-custom-skin > .video-js{
	padding-top: 0 !important;
}
/deep/ .vjs-fluid{
	padding-top: 0 !important;
}
	.index {
		/* 轮播图 */
		.swipe{
			margin-top: 1.2rem;
			width: 100%;
			height: 5.5rem;
			/deep/ .van-swipe__indicators{
				bottom: 0.75rem;
			}
		}
	}
	// 主题内容
	.container {
		// 分类导航
		.gongn{
			width: 100%;
			height: 5.5rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			padding: 1.1rem 0.2rem 0 0.2rem;
			margin-bottom: 0.4rem;
			.box{
				width: 1.78rem;
				height: 1.85rem;
				margin-bottom: 0.5rem;
				display: flex;
				flex-direction: column;
				text-align: center;
				align-items: center;
				img{
					width: 1.1rem;
					height: 1.1rem;
					margin-bottom: 0.11rem;
				}
				P{
					font-size: 0.28rem;
					color: #333;
					span{
						display: inline-block;
						color: #666;
						font-size: 0.22rem;
						margin-top: -0.05rem;
					}
				}
			}
		}
	
		// 走进瑞科
		.goruke{
			background: url(../assets/images/bg3.png) no-repeat top center / contain;
			width: 100%;
			text-align: center;
			// 标题
			.goruke-title{
				div{
					display: flex;
					justify-content: center;
					p{
						font-size: 0.44rem;
						font-weight: 900;
					}
					p:nth-child(1){
						color: #cc6633;
					}
					p:nth-child(2){
						color: #1c5380;
					}	
				}
				p{
					font-size: 0.24rem;
					color: #666;
					margin-top: 0.05rem;
				}
			}
			
			// 数据
			.goruke-dsc{
				display: flex;
				width: 100%;
				justify-content: space-evenly;
				padding: 0 0.2rem;
				div{
					margin-top: 0.35rem;
					text-align: center;
					width: 1.6rem;
					border-right: 1px solid #d8d8d8;
					p:nth-child(1){
						font-weight: 900;
						color: #cc6633;
						font-size: 0.79rem;
						margin-bottom: 0.08rem;
						span{
							font-weight: 100;
							vertical-align: top;
							font-size: 0.28rem;
						}
					}
					p:nth-child(2){
						font-weight: 900;
						font-size: 0.3rem;
						color: #333;
					}
				}
				div:last-child{
					border-right: none;
				}
			}
			// 内容
			.txt{
				padding: 0 30px;
				font-size: 0.29rem;
				color: #666;
				text-indent:2em;
				line-height: 0.5rem;
				text-align: left;
			}
			.txt1{
				padding: 0 30px;
				font-size: 0.29rem;
				color: #666;
				text-indent:2em;
				line-height: 0.5rem;
				text-align: left;
				overflow : hidden;
			}
		
			// 查看更多按钮
			.more{
				width: 5.64rem;
				height: 1rem;
				margin-top: 0.5rem;
				border-radius: 0.5rem;
				background-color: #cc6633;
				color: #fff;
				font-size: 0.32rem;
				border: none;
			}
		}
		
		// 推荐产品
		.tuijian{
			width: 100%;
			margin-top: 1rem;
			text-align: center;
			// 推荐产品
			.tuijian-title{
				margin-bottom: 0.47rem;
				div{
					display: flex;
					justify-content: center;
					p{
						font-size: 0.44rem;
						font-weight: 900;
					}
					p:nth-child(1){
						color: #cc6633;
					}
					p:nth-child(2){
						color: #1c5380;
					}	
				}
				p{
					font-size: 0.24rem;
					color: #666;
					margin-top: 0.05rem;
				}
			}
			// 产品列表
			.tuijian-list{
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 0.5rem;
				border-bottom: 1px solid #eee;
				.item{
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					align-items: center;
					width: 50%;
					background-color: #fff;
					border-right: 1px solid #eee;
					border-top: 1px solid #eee;
					padding-bottom: 0.2rem;
					img{
						width: 100%;
					}
					p{
						font-size: 0.28rem;
						color: #333;
					}
				}
				.item:nth-child(2n){
					border-right: none;
				}
			}
			.more{
				width: 5.64rem;
				height: 1rem;
				border-radius: 0.5rem;
				background-color: #cc6633;
				color: #fff;
				font-size: 0.32rem;
				border: none;
			}
		}
		
		// 资质专利
		.patent{
			width: 100%;
			text-align: center;
			margin-top: 1.1rem;
			position: relative;
			// 标题
			.patent-title{
				margin-bottom: 0.5rem;
				div{
					display: flex;
					justify-content: center;
					p{
						font-size: 0.44rem;
						font-weight: 900;
					}
					p:nth-child(1){
						color: #cc6633;
					}
					p:nth-child(2){
						color: #1c5380;
					}	
				}
				p{
					font-size: 0.24rem;
					color: #666;
					margin-top: 0.05rem;
				}
			}
			// 轮播图
			.patent-swipe{
				width: 100%;
				height: 6.5rem;
				background: url(../assets/images/bg2.png) no-repeat center center / contain;
				.van-swipe-item{
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						width: 3.74rem;
					}
				}
				
			}
			// 轮播图上下按钮
			.next,.prev{
				position: absolute;
				top: 4.2rem;
				right: 0;
			}
			.prev{
				left: 0;
			}
		}
	
		// 新闻资讯
		.news{
			width: 100%;
			text-align: center;
			margin-top: 1.1rem;
			// 标题
			.news-title{
				div{
					display: flex;
					justify-content: center;
					p{
						font-size: 0.44rem;
						font-weight: 900;
					}
					p:nth-child(1){
						color: #cc6633;
					}
					p:nth-child(2){
						color: #1c5380;
					}	
				}
				p{
					font-size: 0.24rem;
					color: #666;
					margin-top: 0.05rem;
				}
			}
			// 新闻分类
			.news-cart{
				padding: 0 0.3rem;
				display: flex;
				margin: 0.4rem 0 ;
				justify-content: space-between;
				.item{
					text-align: center;
					width: 31%;
					height: 1rem;
					background-color: #dbdbdb;
					font-size: 0.28rem;
					color: #666;
					line-height: 1rem;
				}
				// .item:nth-child(1){
				// 	background-color: #1c5380;
				// 	color: #fff;
				// }
				.active{
					background-color: #1c5380;
					color: #fff;
				}
			}
			
			/deep/.news-list .item:nth-child(2){
				background-color: #f1f1f1;
				.xiangqing{
					background: #f1f1f1 !important;
				}
				.item-r{
					p{
						background-color: #f1f1f1 !important;
					}
					&>p:nth-child(1){
						font-family: ms;
						font-size: 0.3rem;
					}
				}
			}	
			/deep/.news-list>img{
				display: block;
			}
			// 新闻列表
			.news-list{
				width: 100%;
				padding: 0 0.3rem;
				text-align: left;
				.item{
					width: 100%;
					height: 1.82rem;
					// background-color: #f1f1f1;
					border-bottom: 1px solid #EAEAEA;
					display: flex;
					align-items: center;
					.item-l{
						width: 1.15rem;
						text-align: center;
						padding:0 0.2rem;
						border-right: 1px solid #d7d7d7;
						p:nth-child(1){
							font-size: 0.48rem;
							font-weight: 900;
							color: #333;
						}
						p:nth-child(2){
							font-size: 0.2rem;
							color: #666;
						}
					}
					.item-r{
						width: 100%;
						height: 100%;
						padding-left: 0.2rem;
						display: flex;
						flex-direction: column;
						justify-content: center;
						.ptitle{
							font-family: ms1;
							font-size: 0.3rem;
							font-weight: 700;
							color: #333;
							margin-bottom: 0.15rem;
							overflow : hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
						.p2{
							position: relative;
							height: 0.7rem;
							overflow: hidden !important;
							.xiangqing{
								width: 1.47rem;
								padding-left: 0.03rem;
								position: absolute;
								right: 0;
								bottom: 0.02rem;
								font-weight: 700;
								font-size: 0.24rem;
								background: #fff;
								color: #666;
								span:nth-child(2){
									color: #CC6633;
								}
							}
							p{
								font-size: 0.24rem;
								color: #666;
								line-height: 0.34rem;
							}
						}
					}
				}
				// .item1{
				// 	background-color: #fff;
				// 	border-bottom: 1px solid #d7d7d7;
				// }
			}
		}
		
		
		// 工程案例
		.case{
			width: 100%;
			// 标题
			.case-title{
				width: 100%;
				padding: 0.45rem 0;
				div{
					display: flex;
					justify-content: center;
					p{
						font-size: 0.44rem;
						font-weight: 900;
					}
					p:nth-child(1){
						color: #cc6633;
					}
					p:nth-child(2){
						color: #1c5380;
					}	
				}
				p{
					font-size: 0.24rem;
					color: #666;
					margin-top: 0.05rem;
				}
			}
			.case-pic{
				width: 100%;
				height: 8.55rem;
				.pic1{
					position: relative;
					width: 100%;
					height: 3.4rem;
					img{
						width: 100%;
						height: auto;
					}
					.txt{
						position: absolute;
						margin: 1.05rem 0.5rem 0 0.65rem;
						top: 0;
						color: #fff;
						line-height: 0.38rem;
						border-left: 1px solid #fff;
						padding-left: 0.2rem;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						-webkit-box-orient: vertical;
						p:nth-child(1){
							font-weight: 900;
							font-size: 0.36rem;
							margin-top: -0.01rem;
							margin-bottom: 0.12rem;
						}
						p:nth-child(2){
							font-size: 0.26rem;
							position: relative;
							bottom: -0.06rem;	
						}
					}
				}
				.pic2{
					display: flex;
					.pic2-l{
						width: 50%;
						img{
							width: 3.75rem;
							height: 2.6rem;
						}
						.txt{
							width: 2.5rem;
							margin: 0.85rem 0.5rem 0 0.65rem;
							overflow : hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							p:nth-child(1){
								margin-top: -0.01rem;
							}
							p:nth-child(2){
								position: relative;
								bottom: -0.06rem;	
							}
						}
					}
					.pic2-r{
						width: 50%;
						height: 2.6rem;
						background-color: #1c5380;
						.position{
							margin-top: -0.06rem !important;
						}
						.txt{
							// width: 2.5rem;.
							overflow : hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							margin: 0.6rem 0.5rem 0 0.65rem;
							p:nth-child(1){
								font-size: 0.2rem;
								font-weight: 100;
								margin-top: -0.01rem;
							}
							p:nth-child(2){
								font-size: 0.36rem;
								position: relative;
								bottom: -0.08rem;	
							}
							button{
								width: 2.16rem;
								height: 0.5rem;
								margin-top: 0.1rem;
								background-color: #cc6633;
								border: none;
								span{
									font-family: 'SimSun';
									margin-left: 0.1rem;
								}
							}
						}
					}
				}
				.pic3{
					margin-top: -0.85rem;
					.pic2-l{
						height: 2.6rem;
						background-color: #cc6633;
						.txt{
							width: 2.5rem;
							margin: 0.65rem 0.5rem 0 0.65rem;
						}
					}
					.pic2-r{
						width: 50%;
						img{
							width: 3.75rem;
							height: 2.6rem;
						}
						.txt{
							width: 2.5rem;
							margin: 0.65rem 0.5rem 0 0.65rem;
							p:nth-child(1){
								font-weight: 900;
								font-size: 0.36rem;
								margin-bottom: 0.12rem;
							}
							p:nth-child(2){
								font-size: 0.26rem;
							}
						}
					}
						
				}
			}
		}
	}
	.search{
		position: relative;
		.ss::after{
			content: '';
			width: 0.35rem;
			height: 0.41rem;
			background-image: url(../assets/images/sousuo.png);
			background-size: 100% 100%;
			position: absolute;
			top: 50%;
			left: 0.2rem;
			transform: translateY(-50%);
		}
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.91rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: #fff !important;
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
		.van-search__content{
			background: #fff !important;
		}
	}
	.gorukeTitle1{
	}
	.gorukeTitle2{
		font-size:12px;
		p{
			display: none !important;
		}
	}
	.fontFZ{
		font-family: tccm !important;
	}
	 .videoBox{
       position: relative;
       width: 100%;
	   height: 5.50rem;
	   background: #000;
       .play{
         position: absolute;
         top: 50%;
         left: 50%;
		 transform: translate(-50%,-50%);
       }
     }
   .playBtn{
       width: 0.6rem !important;
	   height: auto !important;
   }
</style>
<style>
	.gorukeTitle2 p{
		display: none !important;
	}
	.gorukeTitle2 p:nth-child(1){
		display: block !important;
	}
</style>