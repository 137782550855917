<template>
	<div id="patent">
	<!-- 资质专利 -->
	<Nav></Nav>
	<div class="banner">
		<img src="../assets/images/kong.png" >
		<div class="search">
			<!-- 搜索框 -->
			<van-search v-model="searchInput" shape="round" placeholder="你想找什么？" class="ss" background="#fff" @keydown="show($event)" />
		</div>
	</div>
	
	<!-- 资质专利 -->
	<div class="patent"	>
		<!-- 标题 -->
		<div class="patent-title wow animate__animated animate__slideInUp animate__delay-0.5s">
			<div>
				<p>资质</p>
				<p>专利</p>	
			</div>
			<p align="center">QUALIFICATION PATENT</p>
		</div>
		
		<!-- 资质专利图 -->
		<div class="patent-pic">
			<div class="picitem wow animate__animated animate__slideInUp animate__delay-0.5s" @click="showPupop(item.image)" v-for="(item,index) in honor" :key="index">
				<img :src="item.slimage" >
			</div>
		</div>
		
		<!-- 加载更多 -->
		<button class="honor-btn wow animate__animated animate__slideInUp animate__delay-0.5s" @click="more">{{next?'加载更多':'已加载全部'}}</button>
		
		
	</div>
	<Free></Free>
	<Foot></Foot>
	<div style="height: 0.01rem;background:#242424;"></div>
	<div style="height: 0.01rem;background:#242424;"></div>
		<div class="show" @click="showPupop('')">
			<img :src="showImage" alt="">
		</div>
	</div>
</template>

<script>
import Free from '../components/Free.vue'
import Foot from '../components/Foot.vue'
import Nav from '../components/Nav.vue'
import search from '../components/search.vue'
import advantage from '../components/advantage.vue'
import WOW from 'wowjs' 
import $ from 'jquery'
export default{
	data(){
		return {
			honor:[],// 公司荣誉图
			honor1:4,// 
			searchInput:'',
			showImage:'',

			page:1,
			next: true,
		}
	},
	components:{
		Free,
		Foot,
		Nav,
		search,
		advantage
	},
	created() {
		var _this = this
		this.getHonor();
		_this.$apis.get(_this.$apis.inside_banner).then(res=>{
			console.log(res.data,'banner')
			$('.banner>img').attr('src',res.data.data.mobilePatent)
		})
	},
	methods:{
		showPupop(url){
			console.log(url)
			this.showImage = url
			$('.show').fadeToggle()
		},
		show(e){
			if(e.keyCode == 13){
				this.$router.push({
					path:'/product',
					query:{keyStr:this.searchInput}
      			})
			}
		},
		more(){// 公司荣誉加载更多
			if(this.next){
				this.page += 1
				this.getHonor()	
			}else{
				return;
			}
		},
		getHonor(i){
			this.$http.get('https://admin.ruikefamen.com/api/patents/list',
			{params:{page:i?i:this.page}})
			.then(res=>{
				if(res.data.data.data.length>0){
					this.next = true
					this.honor = this.honor.concat(res.data.data.data)
				}else{
					this.next = false
				}
			})
		},
	},
	mounted(){
		new WOW.WOW().init()
	}
}
</script>

<style scoped="scoped" lang="less">
.show{
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
	img{
		width: 80%;height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}
	.banner{
		width: 100%;
		margin-top: 1.18rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	// 资质专利
	.patent{
		margin-top: 0.8rem;
		width: 100%;
		text-align: center;
		margin-bottom: 1rem;
		// height: 4.6rem;
		.patent-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		// 专利图
		.patent-pic{
			margin-top: 0.5rem;
			width: 100%;
			padding: 0 0.3rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.picitem{
				width: 3.35rem;
				height: 3rem;
				background-color: pink;
				overflow: hidden;
				margin-bottom: 0.2rem;
				img{
					width: 100%;
					height: auto;
				}
			}
		}
		// 加载更多按钮
		.honor-btn{
			width: 5.6rem;
			height: 1rem;
			background-color: #cc6633;
			border-radius: 0.5rem;
			border: none;
			color: #fff;
			margin-top: 0.5rem;
			font-size: 0.36rem;
		}
	}
	.search{
		position: relative;
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.96rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: rgba(0,0,0,0);
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
		.van-search__content{
			background: #fff;
		}
	}
</style>
