<template>
	<div id="case">
	<!-- 资质专利 -->
	<Nav></Nav>
	<div class="banner">
		<img src="../assets/images/kong.png" >
		<div class="search">
			<!-- 搜索框 -->
			<van-search v-model="searchInput" shape="round" placeholder="你想找什么？" class="ss" background="#fff" @keydown="show($event)" />
		</div>
	</div>
	
	<!-- 分类筛选 -->
	<div class="screen" v-if="casetypeList">
		<van-dropdown-menu active-color="#cc6633">
		   <van-dropdown-item title="全部分类" ref="item">
		   <van-cell class="allCart">
		     <template #right-icon>
				 <div style="width: 100%;">
					<div class="caliber">
						<div 
						class="caliberlist" 
						style="width: 2.1rem;justify-content: space-between;margin:0.09rem;" 
						v-for="(item,index) in casetypeList"
						@click="cartChange(index,item.id,item.name)"
						:class="{active:index==cartIndex}"
						:key='index'>{{item.name}}</div>
					</div>	 
				 </div>
				<div>
				<button type="submit" block round @click="onReset">重置</button>
				<button block round @click="onConfirm">确认</button>
				</div>
		     </template>
			 
		   </van-cell>
		</van-dropdown-item>
		</van-dropdown-menu>
	</div>
	
	<!-- 案例分类 -->
	<div class="case">
		<div class="bg">
			<img style="width: 100%;" src="../assets/images/bg3.png" alt="">
		</div>
		<!-- 标题 -->
		<div class="case-title wow animate__animated animate__slideInUp animate__delay-0.5s">
			<div>
				<p>{{typeName}}</p>
				<p>案例</p>	
			</div>
			<p align="center">OIL INDUSTRY CASES</p>
		</div>
		
		<!-- 介绍 -->
		<div class="case-txt">
			<p v-html="typeContent">{{typeContent}}</p>
		</div>
		
		<!-- 下面的表 -->
		<div class="case-table">
			<div class="table-item wow animate__animated animate__slideInUp animate__delay-0.5s" v-for="(item,index) in list" :key="index">
				<div class="table-item-l">
					<p>客户名称</p>
					<p>工程项目</p>
					<p>供货阀门</p>
				</div>
				<div class="table-item-r">
					<p>{{item.username}}</p>
					<p>{{item.ojbname}}</p>
					<p>{{item.famen}}</p>
				</div>
			</div>
		</div>
		
		<!-- 加载更多 -->
		<!-- <button class="honor-btn wow animate__animated animate__slideInUp animate__delay-0.5s" @click="caseMore">加载更多</button> -->
		
	</div>
	
	<advantage style="margin-bottom: 0.8rem;"></advantage>
	<Free></Free>
	<Foot></Foot>
	<div style="height: 0.01rem;background:#242424;"></div>
	</div>
</template>

<script>
import Free from '../components/Free.vue'
import Foot from '../components/Foot.vue'
import Nav from '../components/Nav.vue'
import search from '../components/search.vue'
import advantage from '../components/advantage.vue'
import WOW from 'wowjs' 
import $ from 'jquery'
export default{
	data(){
		return {
			cartShow: true,
			cartIndex:0,
			value1: 0,
			cases:[],// 小标题数据
			caseList:[],// 工程案例表格
			caseList1:[],
			casetypeList:[],
			a:0,

			list:[],
			typeId:'',
			typeName:'',
			typeContent:'',
			searchInput:'',
		}
	},
	components:{
		Free,
		Foot,
		Nav,
		search,
		advantage
	},
	created() {
		var _this = this
		if(this.$route.query.id){
			this.typeId = this.$route.query.id
		}
		this.getCases();
		_this.$apis.get(_this.$apis.inside_banner).then(res=>{
			console.log(res.data,'banner')
			$('.banner>img').attr('src',res.data.data.mobileCase)
		})
		// if(this.$route.query.id){
		// 	this.getTable(this.$route.query.id);
		// }else{
		// 	this.getTable();
		// }
	},
	methods:{
		// caseMore(){// 加载更多
		// 	var a = this.caseList.length// 23
		// 	var b = a%5// 
		// 	if(this.caseList1 !== a-a%5){
		// 		var i = 5;
		// 		var a1 = this.caseList1;
		// 		var a2 = this.caseList;
		// 		console.log(this.caseList,'zhaoxiao')
		// 		for (a1 in a2) {
		// 			console.log(this.caseList1,'zhaoxiao')
		// 			this.caseList1 = this.caseList1.concat(this.caseList.splice(i,5));
		// 			i++;
		// 		}
		// 	}
		// 	if(this.caseList1.length == a||this.caseList1.length<=5){
		// 		document.querySelector(".honor-btn").innerText = '已加载全部';
		// 	}
			
		// },
		show(e){
			if(e.keyCode == 13){
				this.$router.push({
					path:'/product',
					query:{keyStr:this.searchInput}
      			})
			}
		},
		cartChange(index,id,name){// 分类
			this.cartIndex = index
			this.a = index
			this.typeId = id
			this.typeName = name
		},
		onConfirm() {// 确认
			// this.a = this.cartIndex;
			// this.$refs.item.toggle();
			// this.$router.push({ query: {} });
			this.getTable();
			this.$refs.item.toggle();
		},
		// 重置全部分类
		onReset(){
			this.cartIndex = 0
			this.typeId = this.casetypeList[0].id
			this.getTable();
			this.$refs.item.toggle();
		},
		getCases(){
			console.log(1)
			this.$apis.get(this.$apis.cases)
			.then(res=>{
				this.cases = res.data.data
				this.casetypeList = res.data.data
				this.typeName = res.data.data[0].name
				this.typeContent = res.data.data[0].content
				console.log(this.casetypeList,"案例类型")
				if(!this.typeId){
					this.typeId = res.data.data[0].id
				}else{
					for(let i=0;i<this.casetypeList.length;i++){
						console.log(this.casetypeList[i].id,Number(this.typeId))
						if(this.casetypeList[i].id == Number(this.typeId)){
							this.cartIndex = i
						}
					}
				}
				console.log(this.cartIndex)
				this.getTable()
			})
		},
		getTable(){
			var _this = this;
			_this.$apis.get(_this.$apis.casesList,{params:{id:this.typeId}}).then(function(res){
				_this.list = res.data.data
			})
		},
		
	},
	mounted(){
		new WOW.WOW().init()
	},
	
}
</script>

<style scoped="scoped" lang="less">
	.banner{
		width: 100%;
		margin-top: 1.18rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	// 分类筛选
	.screen{
		width: 100%;
		height: 1.7rem;
		margin-top: -0.2rem;
		background-color: #eee;
		padding-top: 0.88rem;
		/deep/ .van-dropdown-menu__bar{
			background-color: #eee;
			box-shadow: none;
			// margin-top: 1rem;
		}
		// 打开筛选时下拉框的长度
		/deep/ .van-dropdown-item__content {
			max-height:100%
		}
	
		.van-dropdown-item{
			position: relative;
			width: 100%;
			// height: 4.5rem;
			.allCart{
				width: 100%;
				// height: 4.5rem;
				padding: 0.5rem 0.2rem 1.5rem 0.2rem;
				button{
					position: absolute;
					bottom: 0;
					height: 1.1rem;
					border: none;
					font-size: 0.3rem;
				}
				button:nth-child(1){
					left: 0;
					width: 50%;
					background-color: e7e7e7;
				}
				button:nth-child(2){
					right: 0;
					width: 50%;
					background-color: #cc6633;
					color: #fff;
				}
			}
			.caliber{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				p{
					width: 100%;
					font-size: 0.36rem;
					color: #333;
					margin: 0.25rem 0;
				}
				>div{
					width: 1.6rem;
					height: 0.75rem;
					margin-top: 0.15rem;
					background-color: #fff;
					line-height: 0.75rem;
					text-align: center;
					font-size: 0.28rem;
					color: #333;
					border-radius: 5px;
					border: 1px solid #e3e3e3;
				}
				>div:nth-child(2n){
					margin-left: 0.1rem;
					margin-right: 0.1rem;
				}
				.active{
					background-color: #1d5381;
					color: #fff;
				}
			}
			
		}
		.btn{
			width: 100%;
			position: fixed;
			bottom: 0;
			button{
				width: 50%;
				height: 1.2rem;
				border: none;
				font-size: 0.3rem;
				color: #FFFFFF;
				background-color: #cc6633;
			}
			button:nth-child(1){
				background-color: #e7e7e7;
				color: #666;
			}
			
		}
		
	}
	// 资质专利
	.case{
		margin-top: 0.8rem;
		width: 100%;
		text-align: center;
		margin-bottom: 1rem;
		position: relative;
		.bg{
			position: absolute;
			z-index: -1;
		}

		// 下拉分类
		.case-cart{
			width: 100%;
			height: 1.7rem;
			background-color: #ebebeb;
			margin-top: -0.85rem;
			padding-top: 0.87rem;
			color: #666;
			font-size: 0.28rem;
			margin-bottom: 0.3rem;
			.van-dropdown-menu{
				margin-top: 1.6rem;
			}
			.cart1{
				position: absolute;
				width: 100%;
				background-color: #ebebeb;
				line-height: 0.4rem;
				img{
					width: 0.22rem;
					vertical-align: middle;
					margin-left: 0.08rem;
				}
			}
			.cart2{
				.cart2-t{
					padding: 0 0.3rem;
					display: flex;
					justify-content: space-around;
					p{
						width: 3.25rem;
						text-align: center;
						img{
							width: 0.22rem;
							vertical-align: middle;
							margin-left: 0.08rem;
						}
					}
					p:nth-child(1){
						color: #cc6633;
					}
				}
				.cart2-b{
					position: relative;
					width: 100%;
					height: 5.4rem;
					background-color: #fff;
					margin-top: 0.4rem;
					padding: 0 0.3rem;
					padding-top: 0.2rem;
					.cart-item{
						width: 100%;
						height: 3.4rem;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						.item{
							width: 2.1rem;
							height: 0.9rem;
							margin: 0 0.1rem;
							// margin-top: :;rem;
							line-height: 0.9rem;
							background-color: #fff;
							border: 1px solid #d3d3d3;
							border-radius: 0.08rem;
						}
						.active{
							background-color: #1d5381;
							color: #fff;
						}
					}
					.btn{
						width: 100%;
						button{
							width: 50%;
							height: 1.15rem;
							font-size: 0.3rem;
							border: none;
						}
						button:nth-child(1){
							position: absolute;
							bottom: 0;
							left: 0;
							background-color: #e7e7e7;
							color: #666;
						}
						button:nth-child(2){
							position: absolute;
							bottom: 0;
							right: 0;
							background-color: #cc6633;
							color: #fff;
						}
					}
				}
				
				
			}
		}
		// height: 4.6rem;
		.case-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		.case-txt{
			width: 100%;
			p{
				font-size: 0.27rem;
				color: #333;
				line-height: 0.5rem;
				text-align: left;
				padding: 0.5rem 0.3rem;
				// 两端对齐
				text-align:justify; 
				text-justify:inter-ideograph;
			}
		}
		.case-table{
			padding:0 0.3rem;
			.table-item{
				display: flex;
				height: 2.15rem;
				background-color: #f3f3f3;
				font-size: 0.28rem;
				overflow: hidden;
				margin-bottom: 0.3rem;
				.table-item-l{
					width: 1.68rem;
					height: 100%;
					background-color: #1c5380;
					color: #fff;
					p{
						height: 0.72rem;
						line-height: 0.72rem;
						border-bottom: 1px solid #fff;
					}
				}
				.table-item-r{
					width: calc(100% - 1.68rem);
					p{
						text-align: left;
						text-indent: 0.5em;
						height: 0.72rem;
						line-height: 0.72rem;
						border-bottom: 1px solid #fff;
						color: #666;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
					p:nth-child(1){
						color: #333;
					}
				}
			}
			
		}
		
		// 加载更多按钮
		.honor-btn{
			width: 5.6rem;
			height: 1rem;
			background-color: #cc6633;
			border-radius: 0.5rem;
			border: none;
			color: #fff;
			margin-top: 0.5rem;
			font-size: 0.36rem;
		}
	}
	.search{
		position: relative;
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.96rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: rgba(0,0,0,0);
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
		.van-search__content{
			background: #fff;
		}
	}
</style>
