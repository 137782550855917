<template>
	<div id="conpany">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/kong.png" >
			<div class="search">
				<!-- 搜索框 -->
				<van-search v-model="searchInput" shape="round" left-icon="../" placeholder="你想找什么？" class="ss" background="#fff" @keydown="show($event)" />
			</div>
		</div>
		<div class="conpany-nav">
			<div @click="conpanyShow(index)" v-for="(item,index) in conpanyNav" :key="index"  :class="{active:index==conpanyIndex}" >{{item}}</div>
		</div>
		
		<!-- 公司简介 -->
		<div class="conpany" v-show="conpanyIndex == 0">
			<!-- 标题 -->
			<div class="conpany-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>公司</p>
					<p>简介</p>	
				</div>
				<p align="center">COMPANY PROFILE</p>
			</div>
			
			<!-- 数据 -->
			<div class="conpany-dsc wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p class="fontFZ">{{indexData.famenExp}}<span>+</span></p>
					<p>阀门经验</p>
				</div>
				<div>
					<p class="fontFZ">{{indexData.Patented}}<span>+</span></p>
					<p>专利技术</p>
				</div>
				<div>
					<p class="fontFZ">{{indexData.Testing}}<span>+</span></p>
					<p>加工设备</p>
				</div>
				<div>
					<p class="fontFZ">{{indexData.Customers}}<span>+</span></p>
					<p>合作客户</p>
				</div>
			</div>
			
			<div class="conpany-pic wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div v-for="(item,index) in profile.profileImages" :key="index">
					<img :src="item" v-if="index==0">
				</div>
			</div>
			
			<!-- 公司介绍 -->
			<div class="conpany-txt wow animate__animated animate__slideInUp animate__delay-0.5s">
				<p v-html="profile.content">{{profile.content}}</p>
			</div>
			<div class="xian"></div>
			
			<!-- 企业优势 -->
			<advantage></advantage>
			
			<!-- 全景展示 -->
			<div class="display">
				<!-- 标题 -->
				<div class="display-title wow animate__animated animate__slideInUp animate__delay-0.5s">
					<div>
						<p>全景</p>
						<p>展示</p>	
					</div>
					<p align="center">PANORAMIC DISPLAY</p>
				</div>
				
				<!-- 全景展示图片 -->
				<div class="display-pic wow animate__animated animate__slideInUp animate__delay-0.5s">
					<iframe :src="profile.display" frameborder="0" style="width:100%;height:100%;"></iframe>
				</div>
			</div>
			
			<Free></Free>
			
		</div>
		
		<!-- 组织架构 -->
		<div class="organizational" v-show="conpanyIndex == 1">
			<!-- 标题 -->
			<div class="orgn-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>组织</p>
					<p>架构</p>	
				</div>
				<p align="center">ORGANIZATIONAL STRUCTURE</p>
			</div>
			<!-- 组织架构图 -->
			<div class="orgn-pic wow animate__animated animate__slideInUp animate__delay-0.5s">
				<img :src="structure.sturcImage" >
			</div>
			
			<Free></Free>
		</div>
		
		<!-- 企业文化 -->
		<div class="culture" v-show="conpanyIndex == 2">
			<!-- 标题 -->
			<div class="culture-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>企业</p>
					<p>文化</p>	
				</div>
				<p align="center">CORPORATE CULTURE</p>
			</div>
			
			<!-- 企业文化图文介绍 -->
			<div class="culture-pic" v-if="cultureList.length>0">
				<div class="culture-a box ">
					<img src="../assets/images/qywh1.png" >
					<div class="box-l ">
						<img :src="cultureList[0].image"  class="wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s'>{{cultureList[0].title}}</p>
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s' v-html="cultureList[0].desc">{{cultureList[0].desc}}</p>
					</div>
					<div class="box-r">
						<img :src="cultureList[1].image"  class="wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s'>{{cultureList[1].title}}</p>
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s' v-html="cultureList[1].desc">{{cultureList[1].desc}}</p>
					</div>
				</div>
				<div class="culture-b box">
					<img  src="../assets/images/qywh2.png" >
					<div class="box-l ">
						<img :src="cultureList[2].image"  class="wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s'>{{cultureList[2].title}}</p>
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s' v-html="cultureList[2].desc">{{cultureList[2].desc}}</p>
					</div>
					<div class="box-r">
						<img :src="cultureList[3].image"  class="wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s'>{{cultureList[3].title}}</p>
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s' v-html="cultureList[3].desc">{{cultureList[3].desc}}</p>
					</div>
				</div>
				<div class="culture-c box">
					<img  src="../assets/images/qywh3.png" >
					<div class="box-l ">
						<img :src="cultureList[4].image"  class="wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s'>{{cultureList[4].title}}</p>
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s' v-html="cultureList[4].desc">{{cultureList[4].desc}}</p>
					</div>
					<div class="box-r">
						<img :src="cultureList[5].image"  class="wow animate__animated animate__slideInUp animate__delay-0.5s">
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s'>{{cultureList[5].title}}</p>
						<p class='wow animate__animated animate__slideInUp animate__delay-0.5s' v-html="cultureList[5].desc">{{cultureList[5].desc}}</p>
					</div>
				</div>
			</div>
			
			<Free></Free>
		</div>
		
		<!-- 公司荣誉 -->
		<div class="honor" v-show="conpanyIndex == 3">
			<!-- 标题 -->
			<div class="honor-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>公司</p>
					<p>荣誉</p>	
				</div>
				<p align="center">COMPANY HONOR</p>
			</div>
			
			<!-- 荣誉图 -->
			<div class="honor-pic" v-if="honor">
				<div class="picitem" @click="showPupop(item.image)" v-for="(item,index) in honor" :key="index">
					<img :src="item.slimage" >
				</div>
			</div>
			<!-- 加载更多 -->
			<button class="honor-btn wow animate__animated animate__slideInUp animate__delay-0.5s" @click="more">加载更多</button>
			<Free></Free>
		</div>
		
		<!-- 新闻资讯 -->
		<div class="news" v-show="conpanyIndex == 4">
			<!-- 标题 -->
			<div class="news-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>新闻</p>
					<p>资讯</p>	
				</div>
				<p align="center">NEWS</p>
			</div>
			
			<!-- 新闻资讯下的分类块 -->
			<div class="news-cart wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div class="item" v-for="(item,index) in newsList" :key="index" @click="newsShow(index,item.id)" :class="{active:index==newsIndex}" >{{item.name}}</div>
			</div>
			
			<!-- 新闻列表 -->
			<div class="news">
				<router-link v-for="(item,index) in news" :key="index" :to="{path:'/newsdsc',query:{id:item.id}}" tag="div" class="news-list wow animate__animated animate__slideIn animate__delay-0.5s">
					<div class="item-l">
						<p>{{item.dataTime[2]}}</p>
						<p>{{item.dataTime[0]}}.{{item.dataTime[1]}}</p>
					</div>
					<div class="item-r">
						<p>{{item.title}}</p>
						<!-- <p v-html="item.content">{{item.content_text}}</p> -->
						<p>{{item.content_text}}</p>
					</div>
				</router-link>
				<div v-if="news.length<=0" style="line-height: 30px;text-align: center;color: #999;">
					暂无更多新闻动态
				</div>
				<!-- 加载更多 -->
				<button class="news-btn" @click="newMore">加载更多</button>
				
				<Free></Free>
			</div>
			
		</div>
		<div class="show" @click="showPupop('')">
			<img :src="showImage" alt="">
		</div>
		<Foot></Foot>
		<div style="height: 0.01rem;background:#242424;"></div>
	</div>
</template>

<script>
	import Free from '../components/Free.vue'
	import Foot from '../components/Foot.vue'
	import Nav from '../components/Nav.vue'
	import search from '../components/search.vue'
	import advantage from '../components/advantage.vue'
	import WOW from 'wowjs' 
	import $ from 'jquery'
	export default{
		data(){
			return{
				news1:5,
				conpanyIndex:0,
				newsIndex:0,
				// page:1,
				conpanyNav:['公司简介','组织架构','企业文化','公司荣誉','新闻动态'],
				newsList:'',
				profile:[],// 公司简介
				structure:[],// 组织架构
				honor:[],// 公司荣誉图
				honor1:4,// 
				news:[],// 新闻列表
				cultureList:[],// 企业文化
				cultureListPic:[],

				searchInput:'',

				page:1,
				next:true,
				typeId:'',

				pagee:1,
				nextt:true,

				indexData:'',
				showImage:'',
			}
		},
		created() {
			var _this = this
			this.getData()
			this.newTypeList()//获取新闻分类列表
			this.getProfile();
			this.getStructure();
			this.getHonor();
			this.getCulture();
			if(this.$route.query.i){
				this.conpanyShow(this.$route.query.i)
			}else{
				this.conpanyShow(0)
			}
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
				console.log(res.data,'banner')
				$('.banner>img').attr('src',res.data.data.mobileAbout)
			})
		},
		methods:{
			//查看大图
			showPupop(url){
				this.showImage = url
				$('.show').fadeToggle()
			},
			toChineseNum(num){//数字单位换算
				let dtext=['','十','百','千','万']
				let len=num.toString()
				let numArr=num.toString().split('')
				let numTxt=''
				if(num>=10000){
					numTxt = len.substring(0,len.length - 4) + '万'
				}else{
					numTxt = len
				}
				return numTxt;
			},
			getData(){
				var _this = this
				console.log(1)
				_this.$apis.get(_this.$apis.index).then(function(res) {
					if(res.data.code == 1){
						console.log(res.data.data,"data")
						res.data.data.famenExp = _this.toChineseNum(res.data.data.famenExp)
						res.data.data.Patented = _this.toChineseNum(res.data.data.Patented)
						res.data.data.Testing = _this.toChineseNum(res.data.data.Testing)
						res.data.data.Customers = _this.toChineseNum(res.data.data.Customers)
						_this.indexData = res.data.data
					}
				})
			},
			show(e){
				if(e.keyCode == 13){
					this.$router.push({
						path:'/product',
						query:{keyStr:this.searchInput}
					})
				}
			},
			newMore(){
				if(this.nextt){
					this.pagee+=1
					this.getNewsList()
				}
			},
			more(){// 公司荣誉加载更多
				if(this.next){
					this.page+=1
					this.getHonor()
				}
			},
			conpanyShow(index){
				// this.conpanyIndex = 0
				this.conpanyIndex = index
				this.page = 1
			},
			newsShow(index,id){
				this.newsIndex = index
				this.typeId = id
				this.news = []
				this.pagee = 1
				this.nextt = true
				this.getNewsList()
			},
			getProfile(){// 公司简介
				this.$http.get('https://admin.ruikefamen.com/api/company/profile')
				.then(res=>{
					this.profile = res.data.data
				})
			},
			getStructure(){// 组织架构
				this.$http.get('https://admin.ruikefamen.com/api/company/structure')
				.then(res=>{
					this.structure = res.data.data
				})
			},
			getHonor(){ 	
				this.$http.get('https://admin.ruikefamen.com/api/company/honor_list',
				{params:{page:this.page}})
				.then(res=>{
					if(res.data.data.data.length>0){
						this.next = true
						this.honor = this.honor.concat(res.data.data.data)
					}else{
						this.next = false
					}
				})
			},
			getCulture(){
				var _this = this;
				_this.$apis.get(_this.$apis.culture)
				.then(res=>{
					_this.cultureList = res.data.data.list
					_this.cultureListPic = res.data.data
				})
			},
			//获取新闻动态分类
			newTypeList(){
				var _this = this
				_this.$apis.get(_this.$apis.gettype,{params:{type:'news'}})
				.then(res=>{
					this.newsList = res.data.data
					console.log(res.data.data[0].id)
					this.typeId = res.data.data[0].id
					this.getNewsList()
				})
			},
			getNewsList(){// 新闻动态
				var _this = this;
				let regex = new RegExp('<img', 'gi')
				_this.$apis.get(_this.$apis.newsList,
					{params:{page:this.pagee,categoryid:this.typeId}}
				).then(res=>{
					if(res.data.data.data.length>0){
						this.nextt = true
						for(let i=0;i<res.data.data.data.length;i++){
							console.log(res.data.data.data[i].createtime,'en?')
							res.data.data.data[i].dataTime = res.data.data.data[i].createtime.split('-')
							res.data.data.data[i].content = res.data.data.data[i].content.replace(regex, `<img style="max-width: 100%;display:none;"`)
						}
						console.log(res.data.data.data,'shijian1')
						_this.news = _this.news.concat(res.data.data.data)
					}else{
						this.nextt = false
					}
				})
			}
		},
		components:{
			Free,
			Foot,
			Nav,
			search,
			advantage
		},
		mounted(){
			new WOW.WOW().init()
		}
	}
</script>

<style scoped="scoped" lang="less">
.show{
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
	img{
		width: 80%;height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}
	.banner{
		width: 100%;
		margin-top: 1.18rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	// 五个分类导航
	.conpany-nav{
		width: 100%;
		height: 2.16rem;
		margin-top: 1rem;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		line-height: 1.08rem;
		padding: 0 0.3rem;
		>div{
			width: 2.2rem;
			height: 1rem;
			margin: 0 0.15rem 0.15rem 0;
			background-color: #eee;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			color: #666666;
		}
		>div:nth-child(3){
			margin-right: 0;
		}
		.active{
			background-color: #1d5381;
			color: #fff;
		}
	}
	
	// 公司简介
	.conpany{
		background: url(../assets/images/bg3.png) no-repeat top center / contain;
		width: 100%;
		margin-top: 0.8rem;
		// 标题
		.conpany-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		
		// 数据
		.conpany-dsc{
			display: flex;
			width: 100%;
			justify-content: space-evenly;
			padding: 0 0.2rem;
			div{
				margin-top: 0.35rem;
				text-align: center;
				width: 1.6rem;
				height: 1.2rem;
				border-right: 1px solid #ccc;
				p:nth-child(1){
					font-weight: 900;
					color: #cc6633;
					font-size: 0.79rem;
					font-family: 'FZYaoti';
					margin-bottom: 0.08rem;
					span{
						font-weight: 100;
						vertical-align: top;
						font-size: 0.28rem;
					}
				}
				p:nth-child(2){
					font-weight: 900;
					font-size: 0.3rem;
					color: #333;
				}
			}
			div:last-child{
				border-right: none;
			}
		}
		
		// 公司图片
		.conpany-pic{
			margin: 0.9rem 0 0.65rem 0;
			width: 100%;
			height: 3.9rem;
			overflow: hidden;
			padding: 0 0.3rem;
			img{
				width: 100%;
				height: auto;
			}
		}
		.conpany-txt{
			p{
				text-align: left;
				text-indent: 2em;
				color: #666;
				font-size: 0.28rem;
				padding: 0 0.42rem;
				line-height: 0.5rem;
			}
			p:last-child{
				padding-bottom: 0.65rem;
				// border-bottom: 1px solid #e1e1e1;
			}
		}
		.xian{
			width: 95%;
			margin: 0 auto;
			border-bottom: 1px solid #E1E1E1;
		}
	}
	
	// 全景优势
	.display{
		width: 100%;
		text-align: center;
		margin-top: 1.1rem;
		// 标题
		.display-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		.display-pic{
			width: 6.9rem;
			height: 4.8rem;
			margin: 0.5rem auto 1rem;
			overflow: hidden;
		}
	}
	
	// 组织架构
	.organizational{
		margin-top: 0.8rem;
		width: 100%;
		// height: 4.6rem;
		.orgn-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		// 组织架构图
		.orgn-pic{
			padding: 0 0.3rem;
			height: 3rem;
			margin: 0.5rem 0 1rem 0;
			img{
				width: auto;
				height: 3rem;
			}
		}
	}
	
	// 企业文化
	.culture{
		margin-top: 0.8rem;
		width: 100%;
		// height: 4.6rem;
		.culture-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		// 企业文化图文介绍
		.culture-pic{
			margin-top: 0.5rem;
			margin-bottom: 1rem;
			width: 100%;
			.box{
				height: 5rem;
				width: 100%;
			}
			.culture-a,.culture-b,.culture-c{
				position: relative;
				// width: 100%;
				// height: 100%;
				overflow: hidden;
				img{
					position: absolute;
					top: 0;
					z-index: -1;
					width: auto;
					height: 100%;
				}
				display: flex;
				color: #fff;
				// padding: 0 0.25rem;
				.box-l{
					margin-left: 0.25rem;
					border-right: 1px solid rgba(238,238,238,0.5);
				}
				.box-r{
					margin-right: 0.25rem;
				}
				.box-l,.box-r{
					background-color: rgba(0,0,0,0.3);
					width: 50%;
					padding: 1rem 0.05rem 0 0.2rem;
					line-height: 0.46rem;
					img{
						margin-top: 0.7rem;
						height: 0.6rem;
						width: auto;
					}
					p:nth-child(2){
						font-size:0.36rem;
						margin: 0.5rem 0 0.12rem 0;
					}
					p:nth-child(3){
						font-size:0.24rem;
						line-height: 0.4rem;
					}
				}
			}
		}
	}
	
	
	// 公司荣誉
	.honor{
		margin-top: 0.8rem;
		width: 100%;
		text-align: center;
		margin-bottom: 1rem;
		// height: 4.6rem;
		.honor-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		// 荣誉图
		.honor-pic{
			margin-top: 0.5rem;
			width: 100%;
			padding: 0 0.3rem;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.picitem{
				width: 3.35rem;
				height: 3rem;
				background-color: pink;
				overflow: hidden;
				margin-bottom: 0.2rem;
				img{
					width: 100%;
					height: auto;
				}
			}
			
		}
		// 加载更多按钮
		.honor-btn{
			width: 5.6rem;
			height: 1rem;
			background-color: #cc6633;
			border-radius: 0.5rem;
			border: none;
			color: #fff;
			margin-top: 0.5rem;
			font-size: 0.36rem;
			margin-bottom: 1.5rem;
		}
	}
	
	// 新闻资讯
	.news{
		margin-top: 0.8rem;
		width: 100%;
		text-align: center;
		margin-bottom: 1rem;
		// height: 4.6rem;
		.news-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		.news-cart{
			padding: 0 0.3rem;
			display: flex;
			justify-content: space-between;
			margin: 0.5rem 0;
			.item{
				width: 2.2rem;
				height: 1rem;
				line-height: 1rem;
				border-radius: 0.08rem;
				background-color: #eee;
				color: #666;
				font-size: 0.28rem;
			}
			.active{
				color: #fff;
				background-color: #cc6633;
			}
		}
		// 新闻列表
		.news{
			width: 100%;
			.news-list:nth-child(1){
				border-top: 1px solid #eaeaea;
			}
			// 加载更多按钮
			.news-btn{
				width: 5.6rem;
				height: 1rem;
				background-color: #cc6633;
				border-radius: 0.5rem;
				border: none;
				color: #fff;
				margin-top: 0.5rem;
				font-size: 0.36rem;
				margin-bottom: 1.5rem;
			}
			.news-list{
				height: 1.65rem;
				padding: 0 0.3rem;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #eaeaea;
				.item-l{
					width: 1.8rem;
					padding-right: 0.15rem;
					margin-right: 0.2rem;
					border-right: 1px solid #eaeaea;
					p:nth-child(1){
						font-weight: 900;
						font-size: 0.48rem;
						color: #333;
					}
					p:nth-child(2){
						color: #666;
						font-size: 0.2rem !important;
						p{
							font-size: 0.24rem !important;
						}
					}
				}
				.item-r{
					text-align: left;
					padding-right: 0.15rem;
					overflow: hidden;
					p:nth-child(1){
						font-size: 0.3rem;
						color: #333;
						margin-bottom: 2px;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					p:nth-child(2){
						color: #666;
						font-size: 0.24rem;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
		}
	}
	.search{
		position: relative;
		.ss::after{
			content: '';
			width: 0.35rem;
			height: 0.41rem;
			background-image: url(../assets/images/sousuo.png);
			background-size: 100% 100%;
			position: absolute;
			top: 50%;
			left: 0.2rem;
			transform: translateY(-50%);
		}
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.96rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: #fff !important;
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
		.van-search__content{
			background: #fff;
		}
	}
	.fontFZ{
		font-family: tccm !important;
	}
</style>
