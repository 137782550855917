<template>
	<div id="conpany">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/banner2.png" >
			<div class="search">
				<!-- 搜索框 -->
				<van-search v-model="searchInput" shape="round" left-icon="../assets/images/sousuo.png" placeholder="你想找什么？" class="ss" background="#fff" @keydown="show($event)" />
			</div>
		</div>
		<div class="conpany-nav">
			<div @click="conpanyShow(index)" v-for="(item,index) in conpanyNav" :key="index"  :class="{active:index==conpanyIndex}" >{{item}}</div>
		</div>
		
			<!-- 新闻资讯 -->
		<div class="news" v-show="conpanyIndex == 4">
			<p style="margin-top: 0.5rem;">{{newsList.title}}</p>
			<p>
				<span>发布日期：{{newsList.dataTime[0]}}-{{newsList.dataTime[1]}}-{{newsList.dataTime[2]}}</span> 
				<span>{{newsList.dataTime[3]}}</span>
				<span>点击: {{newsList.click}}</span>
			</p>
			<div class="vhtml" @click="getImg($event)">
				<p v-html="newsList.content" class="newspictxt">{{newsList.content}}</p>
			</div>
			<!-- 上一篇 & 下一篇 -->
			<div v-if="newsList.up"  class="shang" tag="p" @click='shang(newsList.up.id)'>上一篇：{{newsList.up.title}}</div>
			<div v-else class="shang" tag="p">没有上一篇了</div>

			<div v-if="newsList.down" class="xia" @click='xia(newsList.down.id)'>下一篇：{{newsList.down.title}}</div>
			<div v-else class="xia" tag="p">没有下一篇了</div>
		</div>
		<Free></Free>
		<Foot></Foot>
		<div style="height: 0.01rem;background:#242424;"></div>
		<div class="show" @click="getImg('')">
			<img :src="showImage" alt="">
		</div>
	</div>
</template>

<script>
	import Free from '../components/Free.vue'
	import Foot from '../components/Foot.vue'
	import Nav from '../components/Nav.vue'
	import search from '../components/search.vue'
	import advantage from '../components/advantage.vue'
	import $ from 'jquery'
	export default{
		data(){
			return{
				conpanyIndex:4,
				newsIndex:0,
				conpanyNav:['公司简介','组织架构','企业文化','公司荣誉','新闻动态'],
				newsList:['公司动态','行业聚焦','阀门知识'],

				showImage:'',
			}
		},
		created() {
			if(this.$route.query.id){
				this.proNewList(this.$route.query.id)
			}
		},
		methods:{
			show(e){
				if(e.keyCode == 13){
					this.$router.push({
						path:'/product',
						query:{keyStr:this.searchInput}
					})
				}
			},
			getImg(el){
				if(el == ''){
					$('.show').fadeOut()
					return;
				}
				if(el.target.currentSrc){
					this.showImage = el.target.currentSrc
					$('.show').fadeToggle()
				}else{
					$('.show').fadeOut()
				}
			},
			shang(id){
				this.proNewList(id)
				document.body.scrollTop = 0
            	document.documentElement.scrollTop = 0
			},
			xia(id){
				this.proNewList(id)
				document.body.scrollTop = 0
            	document.documentElement.scrollTop = 0
			},
			conpanyShow(index){
				this.conpanyIndex = index
				this.$router.replace({
					path:"/conpany",
					query:{i:index}
				});
			},
			newsShow(index){
				this.newsIndex = index
			},
			proNewList(a){
				let regex = new RegExp('<img', 'gi')
				this.$http.get('https://admin.ruikefamen.com/api/index/news_detail',
				{params:{id:a}}).then(res=>{
				  if(res.data.code == 1){
					res.data.data.dataTime = res.data.data.createtime.split('-')
					console.log(res.data.data,'xiangqing')
					res.data.data.content = res.data.data.content.replace(regex, `<img style="max-width: 100%;display:block;"`)
					this.newsList = res.data.data
				  }
				})
			},
		},
		components:{
			Free,
			Foot,
			Nav,
			search,
			advantage
		}
	}
</script>

<style scoped="scoped" lang="less">
	.banner{
		width: 100%;
		margin-top: 1.18rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	// 五个分类导航
	.conpany-nav{
		width: 100%;
		height: 2.16rem;
		margin-top: 1rem;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		line-height: 1.08rem;
		padding: 0 0.3rem;
		>div{
			width: 2.2rem;
			height: 1rem;
			margin: 0 0.15rem 0.15rem 0;
			background-color: #eee;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			color: #666666;
		}
		>div:nth-child(3){
			margin-right: 0;
		}
		.active{
			background-color: #1d5381;
			color: #fff;
		}
	}
	.news{
		width: 100%;
		padding: 0 0.5rem;
		p:nth-child(1){
			font-size: 0.36rem;
			color: #333;
			text-align: center;
		}
		p:nth-child(2){
			width: 90%;
			margin: 0 auto;
			font-size: 0.24rem;
			color: #999;
			text-align: center;
			height: 0.7rem;
			line-height: 0.7rem;
			margin: 0.3rem 0;
			display: flex;
			justify-content: space-evenly;
			border-bottom: 1px solid #eee;
			border-top: 1px solid #eee;
		}
		.vhtml p{
			font-size: 0.28rem;
			line-height: 0.46rem;
			color: #505050;
			text-indent: 2em;
		}
		.news-pic{
			width: 100%;
			// height: 10.55rem;
			overflow: hidden;
			margin-bottom: 1rem;
			margin-top: 0.5rem;
			img{
				height: 5.2rem;
				width: auto;
				margin-bottom: 0.18rem;
			}
		}
		.shang,.xia{
			height: 0.7rem;
			margin: 0.2rem 0;
			line-height: 0.7rem;
			border-top: 1px solid #eee;
			border-bottom: 1px solid #eee;
			font-size: 0.28rem;
			color: #999;
			white-space:nowrap;
			overflow:hidden;
			text-overflow:ellipsis;
		}
		.xia{
			margin-bottom: 1rem;
		}
		.newspictxt{
			/deep/ p{
				text-align: left;
			}
			/deep/ p:nth-child(2),
			/deep/ p:nth-child(3){
				text-indent: 0;
				img{
					width: 100%;
					margin: 0.05rem 0;
				} 	
			}
			
		}
	}
	.show{
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		background: rgba(0, 0, 0, 0.5);
		img{
			width: 80%;height: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}
	.ss::after{
			content: '';
			width: 0.35rem;
			height: 0.41rem;
			background-image: url(../assets/images/sousuo.png);
			background-size: 100% 100%;
			position: absolute;
			top: 50%;
			left: 0.2rem;
			transform: translateY(-50%);
		}
		.search{
		position: relative;
		.ss::after{
			content: '';
			width: 0.35rem;
			height: 0.41rem;
			background-image: url(../assets/images/sousuo.png);
			background-size: 100% 100%;
			position: absolute;
			top: 50%;
			left: 0.2rem;
			transform: translateY(-50%);
		}
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.91rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: #fff !important;
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
		.van-search__content{
			background: #fff;
		}
	}
</style>
