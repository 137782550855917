import axios from 'axios'; //引入axios
import qs from 'qs';	//引入axios数据处理

axios.defaults.timeout = 20000;//设置请求超时时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';//设置post方法的数据格式

//请求拦截器
axios.interceptors.request.use(config=>{
  if(config.method  === 'post'){
    //发送至后台的是对象   使用qs转换为字符串
    config.data = qs.stringify(config.data);
  }
  return config;
},error=>{
  return Promise.error(error);
})

//响应拦截器
axios.interceptors.response.use(res=>{
  console.log(res)
  return res;
},error=>{
  if (error.response.status) {
    return Promise.reject(error.response);
  }
})

console.log(window.location.href)

//请求IP
let second = process.env.NODE_ENV === 'production' ? 'https://admin.ruikefamen.com' : 'https://admin.ruikefamen.com'
axios. second = process.env.NODE_ENV === 'production' ? 'https://admin.ruikefamen.com' : 'https://admin.ruikefamen.com'

//接口地址
axios.question = `${second}/api/service/question`;//常见问题
axios.userbackFrom = `${second}/api/service/userback`;//用户反馈
axios.cases = `${second}/api/cases/type_list`;//案例分类
axios.casesList = `${second}/api/cases/case_list`;// 分类下案例
axios.patents = `${second}/api/patents/list`;// 资质专利
axios.productsList = `${second}/api/products/list`;// 产品列表
axios.commit = `${second}/api/index/quotation_commit`;// 报价留言
axios.index = `${second}/api/index/index_data`;// 首页
axios.detail = `${second}/api/products/detail`;// 详情页
axios.newsList = `${second}/api/index/news_list`;// 新闻列表
axios.newsDel = `${second}/api/index/news_detail`;// 新闻列表
axios.banner = `${second}/api/index/index`;// 新闻列表
axios.model = `${second}/api/index/index_mobile`;// 移动端首页
axios.allType = `${second}/api/products/all_type`;// 全部分类
axios.culture = `${second}/api/company/culture`;// 企业文化
axios.gettype = `${second}/api/index/gettype`;// 平台分类
axios.question_detail = `${second}/api/service/question_detail`;// 常见问题详情
axios.inside_banner = `${second}/api/index/inside_banner`;// 主要页面顶部图

export default axios;




/*复制专用
  _this.apis.post(_this.$apis.payOrder).then(function(res) {
    if(res.data.code == 1){

    }
  })
*/





