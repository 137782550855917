<template>
  <div id="app">
	<router-view class= 'pages'></router-view>
	<div class="tabs" v-show="this.$route.path != '/prodsc'">
		<router-link class="col" to="/" tag="p">首页</router-link>
		<router-link class="col about" to="/conpany" tag="p">关于</router-link>
		<router-link class="col product" to="/product" tag="p">产品</router-link>
		<router-link class="col content" to="/contactus" tag="p">联系</router-link>
	</div>
  </div>
</template>

<script>
import $ from 'jquery'
	var sUserAgent=navigator.userAgent;
	var mobileAgents=['Android','iPhone','Symbian','WindowsPhone','iPod','BlackBerry','Windows CE'];
	var goUrl = 0;
	for( var i=0;i<mobileAgents.length;i++){
		if(sUserAgent.indexOf(mobileAgents[i]) > -1){
			goUrl = 1;
			break;
		}
	}
	if (goUrl !== 1){
		location ='https://www.ruikefamen.com';
	}

window.onload = function() {
  document.addEventListener('touchstart', function(event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function(event) {
    event.preventDefault()
  })
}
export default {
	name:'app',
	data(){
		return {
			index:'',
		}
	},
	mounted(){
		this.$http.get("https://admin.ruikefamen.com/api/index/index").then(res=>{
			if(res.data.code == 1){
				this.index = res.data.data
				// steamLogo	
				document.title = res.data.data.site_name
				console.log(res.data.data.wapIcon,res.data.data.site_name)
				$('head').append("<meta name='keywords' content="+res.data.data.keyword+">");
				$('head').append("<link rel='icon' href="+res.data.data.wapIcon+">");
			}
		})
	}
}
</script>

<style>

@font-face {
	font-family: ms;
	src: url("./assets/font/ms.ttf");
}
@font-face {
	font-family: ms1;
	src: url("./assets/font/ms1.ttf");
}
@font-face {
	font-family: tccm;
	src: url("./assets/font/TCCM.TTF");
}
#app{
	position: relative;
}
.pages{
	margin-bottom: 1.1rem;
}
.tabs{
	display: flex;
	justify-content: space-around;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	height: 1.1rem;
	background-color: #bf5d30;
	text-align: center;
	font-size: 0.24rem;
	color: #fff;
}
.tabs .col:before{
	content: '';
	display: block;
	height: 0.55rem;
	width: 0.55rem;
	background: url(./assets/images/shouy.png) no-repeat center center / contain;
	background-position: center center; 
	background-size: 0.5rem;
	margin: 0.08rem auto;
	margin-bottom: 0.05rem;
}
.tabs .about:before{
	background: url(./assets/images/about.png)no-repeat center center / 0.67rem 0.47rem;
	width: 0.67rem;
}
.tabs .product:before{
	background-image: url(assets/images/product.png);
}
.tabs .content:before{
	background-image: url(assets/images/lainxi.png);
}

</style>
