<template>
	<div id="advant">
		<!-- 企业优势 -->
		<div class="advantage" >
			<!-- 标题 -->
			<div class="advantage-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>企业</p>
					<p>优势</p>	
				</div>
				<p align="center">ENTERPRISE ADVANTAGE</p>
			</div>
			
			<!-- <div v-for="(itme,index) in goodness.length"> -->
				<!-- 轮播图 -->
			<van-swipe class="swipe" :autoplay="30000" @change="swiperChange" :show-indicators="false">
				<van-swipe-item v-for="(item,index) in goodness" :key="index">
					<div>
						<div class="advantage-pic wow animate__animated animate__slideInUp animate__delay-0.5s">
							<img :src="item.image">
						</div>
						<!-- 优势列表 -->
						<div class="advantage-list">
							<p class="tt wow animate__animated animate__slideInLeft animate__delay-0.5s">{{item.title}}</p>
							<p v-for="(itemm,index) in item.tags_text" :key="index" class=" wow animate__animated animate__slideInLeft animate__delay-0.5s" style="padding-right: 0.2rem	;">
								<img src="../assets/images/dui.png" >
								<span>{{itemm}}</span>
							</p>
						</div>
					</div>
				</van-swipe-item>
			</van-swipe>
			<!-- 切换优势列表  -->
			<div class="advantage-huan">
				<div class="box" v-for="(item,index) in goodness.length" :key="index" :class="{active:index==cartIndex}"></div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import WOW from 'wowjs' 
	export default{
		data(){
			return{
				cartIndex:0,
				a:0,
				res:[],
				goodness:[],
			}
		},
		created() {
			this.getIndex();
		},
		methods:{
			//监听轮播
			swiperChange(index){
				this.cartIndex = index
			},
			getIndex(){
				var _this = this
				_this.$apis.get(_this.$apis.model)
				.then(res=>{
				  if(res.data.code == 1){
					  _this.goodness = res.data.data.goodness
					  _this.res = res.data.data
				  }
				})
			}
		},
		mounted(){
			new WOW.WOW().init()
		}
	}
	
</script>

<style scoped="scoped" lang="less">
	// 企业优势
	.advantage{
		width: 100%;
		padding: 0 0.3rem;
		text-align: center;
		margin-top: 0.65rem;
		// 标题
		.advantage-title{
			width: 100%;
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		.advantage-pic{
			margin-top: 0.7rem;
			overflow: hidden;
			img{
				display: block;
				width: 100%;
			}
		}
		.advantage-list{
			width: 100%;
			background-color: #f1f1f1;
			text-align: left;
			padding-left: 0.35rem;
			padding-bottom: 0.53rem;
			.tt{
				padding: 0.51rem 0 0.39rem !important;
			}
			p{
				color: #333;
				font-size: 0.46rem;
				font-weight: 900;
				img{
					width: 0.35rem;
					height: 0.35rem;
					vertical-align: middle;
					margin-right: 0.15rem;
				}
			}
			p:nth-child(2),p:nth-child(3),p:nth-child(4),p:nth-child(5){
				line-height: 0.4rem;
				margin: 0.1rem 0;
				span{
					font-size: 0.28rem;
					font-weight: normal;
				}
			}
			p:nth-child(2){
				margin-top: 0;
			}
		}
		.advantage-huan{
			width: 2rem;
			height: 0.06rem;
			margin: 0.4rem auto;
			display: flex;
			justify-content: space-between;
			.box{
				width: 22%;
				height: 100%;
				background-color: #dadada;
			}
			.active{
				background-color: #cc6633;
			}
		}
	}
</style>
