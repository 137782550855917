<template>
	<div id="conpany">
		<Nav></Nav>
		<div class="banner">
			<img src="../assets/images/kong.png" >
			<div class="search">
				<!-- 搜索框 -->
				<van-search v-model="searchInput" shape="round" placeholder="你想找什么？" class="ss" background="#fff" @keydown="show($event)" />
			</div>
		</div>
		<div class="conpany-nav">
			<div @click="conpanyShow(index)" v-for="(item,index) in conpanyNav" :key="index"  :class="{active:index==conpanyIndex}" >{{item}}</div>
		</div>
		
		<!-- 售前服务 -->
		<div class="service" v-show="conpanyIndex == 0">
			<!-- 标题 -->
			<div class="service-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>售前</p>
					<p>服务</p>	
				</div>
				<p align="center">PRE SALES SERVICE</p>
			</div>
			<div class="service-pic wow animate__animated animate__slideInUp animate__delay-0.5s">
				<img :src="saleDsc.saleImage" >
			</div>
			<div class="service-txt wow animate__animated animate__slideInUp animate__delay-0.5s">
				<p v-html="saleDsc.saleContent">{{saleDsc.saleContent}}</p>
			</div>
			
			<div class="service-pic2 wow animate__animated animate__slideInUp animate__delay-0.5s">
				<p>询价、订货流程</p>
				<img :src="saleDsc.saleServiceImage" >
			</div>
			
			<Free></Free>
			
		</div>
		
		<!-- 售后服务 -->
		<div class="after" v-show="conpanyIndex == 1">
			<!-- 标题 -->
			<div class="after-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>售后</p>
					<p>服务</p>	
				</div>
				<p align="center">AFTER-SALE SERVICE</p>
			</div>
			<div class="after-pic wow animate__animated animate__slideInUp animate__delay-0.5s">
				<img src="../assets/images/shouh.png" >
			</div>
			<div class="after-txt wow animate__animated animate__slideInUp animate__delay-0.5s">
			<p v-html="saleafterDsc.aftersaleContent">{{saleafterDsc.aftersaleContent}}</p>
			</div>
			
			<div class="after-pic2 wow animate__animated animate__slideInUp animate__delay-0.5s">
				<p>售后服务流程</p>
				<img src="../assets/images/shouh22.png" >
			</div>
			
			<Free></Free>
			
		</div>
				
				
		
		<!-- 质量承诺 -->
		<div class="quality" v-show="conpanyIndex == 2">
			<!-- 标题 -->
			<div class="quality-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>质量</p>
					<p>承诺</p>	
				</div>
				<p align="center">QUALITY COMMITMENT</p>
			</div>
			<div class="quality-pic wow animate__animated animate__slideInUp animate__delay-0.5s">
				<img src="../assets/images/zhiliang.png" >
			</div>
			<div class="quality-txt wow animate__animated animate__slideInUp animate__delay-0.5s">
				<p v-html="quality.quality" style="text-indent: 0em;padding: 0 0.2rem;">{{quality.quality}}</p>
			</div>
			
			<div class="quality-pic2 wow animate__animated animate__slideInUp animate__delay-0.5s">
				<!-- <img src="../assets/images/tubiao.png" > -->
			</div>
			
			<Free></Free>
			
		</div>
		
		<!-- 常见问题 -->
		<div class="common" v-show="conpanyIndex == 3">
			<!-- 标题 -->
			<div class="common-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>常见</p>
					<p>问题</p>	
				</div>
				<p align="center">COMMON PROBLEM</p>
			</div>
			<div class="newsDetail" v-if="detailType">
				<p style="margin-top: 0.5rem;">{{detailDetail.name}}</p>
				<p>
					<span>发布日期：{{detailDetail.dataTime[0]}}-{{detailDetail.dataTime[1]}}-{{detailDetail.dataTime[2]}}</span> 
					<span>{{detailDetail.dataTime[3]}}</span>
					<span>点击: {{detailDetail.click}}</span>
				</p>
				<p v-html="detailDetail.content" class="newspictxt">{{detailDetail.content}}</p>
				<!-- 上一篇 & 下一篇 -->
				<div v-if="detailDetail.up"  class="shang" tag="p" @click='qiehuan(detailDetail.up.id)'>上一篇：{{detailDetail.up.name}}</div>
				<div v-else class="shang" tag="p">没有上一篇了</div>

				<div v-if="detailDetail.down" class="xia" @click='qiehuan(detailDetail.down.id)'>下一篇：{{detailDetail.down.name}}</div>
				<div v-else class="xia" tag="p">没有下一篇了</div>
			</div>
			<!-- 常见问题 -->
			<div class="news" v-else>
				<div @click="goDetail(item.id)" v-for="(item,index) in question" :key="index" tag="div" class="news-list wow animate__animated animate__slideInLeft animate__delay-0.5s">
					<div class="item-l">
						<p>{{item.dataTime[2]}}</p>
						<p>{{item.dataTime[0]}}.{{item.dataTime[1]}}</p>
					</div>
					<div class="item-r">
						<p>{{item.name}}</p>
						<!-- <p v-html="item.content">{{item.content_text}}</p> -->
						<p>{{item.content_text}}</p>
					</div>
					
				</div>
				<!-- 加载更多 -->
				<button class="news-btn wow animate__animated animate__slideInUp animate__delay-0.5s" @click="more">加载更多</button>
			</div>
			<Free></Free>
			
		</div>
		
		<!-- 用户反馈 -->
		<div class="user" v-show="conpanyIndex == 4">
			<!-- 标题 -->
			<div class="user-title wow animate__animated animate__slideInUp animate__delay-0.5s">
				<div>
					<p>用户</p>
					<p>反馈</p>	
				</div>
				<p align="center">USER FEEDBACK</p>
			</div>
			
			<div class="user-table wow animate__animated animate__slideInUp animate__delay-0.5s">
				  <van-field
				  	class="input input1"
				    v-model="username"
				    name="您的姓名"
				    placeholder="您的姓名:"
				  />
				  <van-field
				  	class="input"
				    v-model="address"
				    name="联系地址"
				    placeholder="联系地址:"
				  />
				  <van-field
				  	class="input"
				    v-model="conpany"
				    name="公司名称"
				    placeholder="公司名称:"
				  />
				  <van-field
				  	class="input input1"
				    v-model="tel"
				    name="联系电话"
				    placeholder="联系电话:"
				  />
				  <van-field
				  	class="input"
				    v-model="email"
				    name="联系邮箱"
				    placeholder="联系邮箱:"
				  />
				<div class="user-textarea">
					<textarea placeholder="留言内容：" v-model="desc"></textarea>
				</div>
				<div style="margin: 16px;">
				  <van-button round block native-type="submit" class="submitbtn wow animate__animated animate__slideInUp animate__delay-0.5s" @click="getUserback">提交反馈</van-button>
				</div>
			</div>
			
		</div>
		
		<Foot></Foot>
		<div style="height: 0.01rem;background:#242424;"></div>
	</div>
</template>

<script>
	import Free from '../components/Free.vue'
	import Foot from '../components/Foot.vue'
	import Nav from '../components/Nav.vue'
	import search from '../components/search.vue'
	import advantage from '../components/advantage.vue'
	import WOW from 'wowjs'
	import $ from 'jquery'
	export default{
		data(){
			return{
				detailType:false,
				detailDetail:'',
				content:'',
				username: '',
				tel: '',
				email:'',
				conpany:'',
				desc:'',
				address:'',
				conpanyIndex:0,
				newsIndex:0,
				page:1,
				conpanyNav:['售前服务','售后服务','质量承诺','常见问题','用户反馈'],
				newsList:['公司动态','行业聚焦','阀门知识'],
				saleDsc:[],// 存放售前服务数据
				saleafterDsc:[],// 存放售后服务数据
				question:[],// 常见问题
				quality:[],// 质量承诺
				searchInput:'',
				next:true,
			}
		},
		created() {
			var _this = this
			this.getSale();
			this.getSaleafter();
			this.getQuestion();
			this.getQuality();
			_this.$apis.get(_this.$apis.inside_banner).then(res=>{
			console.log(res.data,'banner')
			$('.banner>img').attr('src',res.data.data.mobileService)
		})
		},
		methods:{
			qiehuan(id){
				this.goDetail(id)
				$("html,body").animate({"scrollTop":"0"},500)
			},
			goDetail(id){
				var _this = this;
				console.log(id)
				if(id){
					_this.$apis.get(_this.$apis.question_detail,{params:{id:id}}).then(function(res) {
						if(res.data.code == 1){
							console.log(res.data.data,"问题详情")
							res.data.data.dataTime = res.data.data.createtime.split('-')
							_this.detailDetail = res.data.data
							_this.detailType = true
						}
					})
				}
			},
			show(e){
			if(e.keyCode == 13){
				this.$router.push({
					path:'/product',
					query:{keyStr:this.searchInput}
      			})
			}
		},
			conpanyShow(index){
				this.conpanyIndex = index
				this.detailType = false
			},
			newsShow(index){
				this.newsIndex = index
			},
			getUserback(){
				var _this = this;
				_this.$apis.post(_this.$apis.userbackFrom,{
					name:_this.username,
					address:_this.address,
					phone:_this.tel,
					cname:_this.conpany,
					email:_this.email,
					content:_this.desc,
				}).then(function(res) {
					if(res.data.code == 1){
						_this.username='';
						_this.address='';
						_this.tel='';
						_this.conpany='';
						_this.email='';
						_this.desc='';
						_this.$Notify({ type: 'success', message: '反馈成功' });
					}else{
						_this.$Notify({ type: 'warning', message: res.data.msg });
					}
				})
			},
			getSale(){// 售前服务
				this.$http.get('https://admin.ruikefamen.com/api/service/sale')
				.then(res=>{
					this.saleDsc = res.data.data
				})
				.catch(err=>{console.error(err)})
			},
			getSaleafter(){// 售后服务
				this.$http.get('https://admin.ruikefamen.com/api/service/saleafter')
				.then(res=>{
					this.saleafterDsc = res.data.data
				})
				.catch(err=>{console.error(err)})
			},
			getQuality(){// 质量承诺
				this.$http.get('https://admin.ruikefamen.com/api/service/quality')
				.then(res=>{
					this.quality = res.data.data
					console.log(this.quality)
				})
				.catch(err=>{console.error(err)})
			},
			getQuestion(i){// 常见问题
				var _this = this;
				_this.$apis.get(_this.$apis.question,{params:{page:_this.page}}).then(function(res) {
					console.log(res.data.data.data,"数量")
					if(res.data.data.data.length>0){
						_this.next = true
						console.log(res.data.data.data,'list')
						for(let i=0;i<res.data.data.data.length;i++){
							console.log(res.data.data.data[i].createtime,'time')
							res.data.data.data[i].dataTime = res.data.data.data[i].createtime.split('-')
						}
						_this.question = _this.question.concat(res.data.data.data)
					}else{
						_this.next = false
					}
				})
			},
			getQuestion1(i){// 常见问题
				var _this = this;
				_this.$apis.get(_this.$apis.question,
					{params:{page:i?i:_this.page}}
				)
				.then(function(res) {
					var question1 = res.data.data.data
					var Arryques = _this.question.data
					Arryques.push(question1[0])
				  })
			},
		
			more(){// 加载更多
				console.log(this.next)
				if(this.next){
					this.page++
					this.getQuestion()
				}
			}
		},
		components:{
			Free,
			Foot,
			Nav,
			search,
			advantage
		},
		mounted(){
			new WOW.WOW().init()
		}
	}
</script>

<style scoped="scoped" lang="less">
	.banner{
		width: 100%;
		margin-top: 1.18rem;
		img{
			width: 100%;
			height: auto;
		}
	}
	// 五个分类导航
	.conpany-nav{
		width: 100%;
		height: 2.16rem;
		margin-top: 1rem;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		line-height: 1.08rem;
		padding: 0 0.3rem;
		>div{
			width: 2.2rem;
			height: 1rem;
			margin: 0 0.15rem 0.15rem 0;
			background-color: #eee;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			color: #666666;
		}
		>div:nth-child(3){
			margin-right: 0;
		}
		.active{
			background-color: #1d5381;
			color: #fff;
		}
	}
	
	// 售前服务
	.service{
		width: 100%;
		margin-top: 0.8rem;
		text-align: center;
		// 标题
		.service-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		// 售前服务图
		.service-pic{
			width: 100%;
			height: 4.85rem;
			margin-top: 0.5rem;
			text-align: center;
			img{
				height: auto;
				width: 100%;
			}
		}
		// 服务宗旨
		.service-txt{
			width: 94%;
			margin: 0 auto;
			padding: 0.48rem 0.3rem;
			// margin: 0 0.3rem;
			height: 8.6rem;
			position: relative;
			top: -1.2rem;
			box-sizing: border-box;
			background-color: #1d5381;
			border-radius: 0.1rem;
			p{
				width: 100%;
				color: #fff;
				line-height: 0.56rem;
				text-align: left;
				font-size: 0.28rem;
			}
			
		}
		.service-pic2{
			width: 100%;
			margin-bottom: 1rem;
			text-align: center;
			p{
				font-size: 0.36rem;
				font-weight: 900;
				color: #1d5381;
			}
			img{
				height: 4.42rem;
				width: auto;
			}
		}
	}
	
	// 售后服务
	.after{
		width: 100%;
		margin-top: 0.8rem;
		// text-align: center;
		// 标题
		.after-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		.after-pic{
			width: 100%;
			height: 4.85rem;
			margin-top: 0.5rem;
			img{
				height: 100%;
				width: auto;
			}
		}
		.after-txt{
			width: 94%;
			margin: 0 auto;
			padding: 0.48rem 0.3rem;
			position: relative;
			top: -1.2rem;
			box-sizing: border-box;
			background-color: #1d5381;
			border-radius: 0.1rem;
			p{
				width: 100%;
				color: #fff;
				line-height: 0.56rem;
				text-align: left;
				font-size: 0.3rem;
				margin-bottom: 0.4rem;
			}
		}
		.after-pic2{
			width: 100%;
			margin-bottom: 1rem;
			text-align: center;
			p{
				font-size: 0.36rem;
				font-weight: 900;
				color: #1d5381;
			}
			img{
				height: 1.92rem;
				width: auto;
				margin-top: 1rem;
			}
		}
	}
	
	// 质量承诺
	.quality{
		width: 100%;
		margin-top: 0.8rem;
		// 标题
		.quality-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		.quality-pic{
			width: 100%;
			height: 4.85rem;
			margin-top: 0.5rem;
			img{
				height: 100%;
				width: auto;
			}
		}
		.quality-txt{
			width: 94%;
			margin: 0 auto;
			padding: 0.45rem 0.3rem 0.2rem 0.3rem;
			position: relative;
			top: -1.2rem;
			box-sizing: border-box;
			background-color: #1d5381;
			border-radius: 0.1rem;
			margin-bottom: 3.5rem;
			p{
				width: 100%;
				color: #fff;
				line-height: 0.56rem;
				text-align: left;
				font-size: 0.28rem;
				// margin-bottom: 0.4rem;
				/deep/ ol {
					padding:0 0.3rem;
				}
				/deep/ img{
					float: left;
					margin-top: 1.7rem;
				}
			}
			p:nth-child(1){
				text-indent: 2em;
			}
			p:last-child{
				margin-bottom: 0;
			}
			
		}
		.quality-pic2{
			width: 100%;
			margin-bottom: 0.75rem;
			text-align: center;
			img{
				height: 3.58rem;
				width: auto;
				margin-top: -0.5rem;
			}
		}
	}
	
	// 常见问题
	.common{
		width: 100%;
		margin-top: 0.8rem;
		// 标题
		.common-title{
			margin-bottom: 0.5rem;
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		// 新闻列表
		.news{
			padding: 0 0.3rem;
			width: 100%;
			text-align: center;
			.news-list:nth-child(1){
				border-top: 1px solid #eaeaea;
			}
			// 加载更多按钮
			.news-btn{
				width: 5.6rem;
				height: 1rem;
				background-color: #cc6633;
				border-radius: 0.5rem;
				border: none;
				color: #fff;
				margin-top: 0.5rem;
				font-size: 0.36rem;
				margin-bottom: 1rem;
			}
			.news-list{
				height: 1.65rem;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #eaeaea;
				.item-l{
					width: 1.6rem;
					margin-right: 0.2rem;
					border-right: 1px solid #eaeaea;
					p:nth-child(1){
						font-weight: 900;
						font-size: 0.48rem;
						color: #333;
					}
					p:nth-child(2){
						color: #666;
						font-size: 0.2rem;
					}
				}
				.item-r{
					text-align: left;
					padding-right: 0.15rem;
					height: 1.2rem;
					overflow: hidden;
					p:nth-child(1){
						font-size: 0.3rem;
						color: #333;
						margin-bottom: 2px;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					p:nth-child(2){
						color: #666;
						font-size: 0.24rem;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}
		}
		
		
		
	}
	
	// 用户反馈
	.user{
		margin-top: 0.8rem;
		width: 100%;
		text-align: center;
		margin-bottom: 1rem;
		// height: 4.6rem;
		.user-title{
			div{
				display: flex;
				justify-content: center;
				p{
					font-size: 0.44rem;
					font-weight: 900;
				}
				p:nth-child(1){
					color: #cc6633;
				}
				p:nth-child(2){
					color: #1c5380;
				}	
			}
			p{
				font-size: 0.24rem;
				color: #666;
				margin-top: 0.05rem;
			}
		}
		.user-table{
			width: 6.96rem;
			margin: 0 auto;
			padding-top: 0.49rem;
			background-color: #fff;
			left: 0;
			right: 0;
			margin: 0 auto;
			border-radius: 5px;
			.van-form{
				padding: 0.32rem;
				.van-field{
					width: 100%;
					border-radius: 5px;
					margin-bottom: 0.12rem;
					background-color: #f7f7f7;
					color: #666;
				}
				
			}
			.user-textarea{
				width: 100%;
				padding:0 0.32rem;
				margin-top: 0.1rem;
				textarea{
					width: 100%;
					height: 2.8rem;
					border-radius: 5px;
					text-indent: 0.5em;
					padding-top: 0.1rem;
					font-size: 0.26rem;
					color: #323233;
					background-color: #f7f7f7;
					resize: none;
					border: none;
				}
				// 更改提示框字体样式S
				textarea::-webkit-input-placeholder{color:#ccc;text-indent:0.5em;font-size: 0.26rem;}
			}
			.submitbtn{
				height: 0.96rem;
				width: 5.7rem;
				background-color: #cc6633;
				font-size: 0.36rem;
				color: #FFFFFF;
				margin: 0 auto;
			}
		}
	}
	.search{
		position: relative;
		.ss{
			// 搜索框
			position: absolute;
			width: 6.92rem;
			height: 0.96rem;
			left: 0;right: 0;
			margin: 0 auto;
			top: -0.45rem;
			background-color: rgba(0,0,0,0);
			border-radius: 45px;
			padding: 0;
			box-shadow: 0px 13px 12px 1px #f0f0f0;
		}
	}
	.newsDetail{
		width: 100%;
		padding: 0 0.5rem;
		p:nth-child(1){
			font-size: 0.36rem;
			color: #333;
			text-align: center;
		}
		p:nth-child(2){
			width: 90%;
			margin: 0 auto;
			font-size: 0.24rem;
			color: #999;
			text-align: center;
			height: 0.7rem;
			line-height: 0.7rem;
			margin: 0.3rem 0;
			display: flex;
			justify-content: space-evenly;
			border-bottom: 1px solid #eee;
			border-top: 1px solid #eee;
		}
		p:nth-child(3){
			font-size: 0.28rem;
			line-height: 0.46rem;
			color: #505050;
			text-indent: 2em;
		}
		.news-pic{
			width: 100%;
			// height: 10.55rem;
			overflow: hidden;
			margin-bottom: 1rem;
			margin-top: 0.5rem;
			img{
				height: 5.2rem;
				width: auto;
				margin-bottom: 0.18rem;
			}
		}
		.shang,.xia{
			height: 0.7rem;
			margin: 0.2rem 0;
			line-height: 0.7rem;
			border-top: 1px solid #eee;
			border-bottom: 1px solid #eee;
			font-size: 0.28rem;
			color: #999;
			white-space:nowrap;
			overflow:hidden;
			text-overflow:ellipsis;
		}
		.xia{
			margin-bottom: 1rem;
		}
		.newspictxt{
			/deep/ p:nth-child(2),
			/deep/ p:nth-child(3){
				text-indent: 0;
				img{
					width: 100%;
					margin: 0.05rem 0;
				} 	
			}
			
		}
	}
	.input{
		display: inline-block;
		width: 6.34rem;
		margin: 0.05rem auto;
		border-radius: 0.08rem;
		position: relative;
		background: #F7F7F7;
	}
	.input1::after{
		content: '*';
		color: #CC6633;
		position: absolute;
		right: -5.2rem;
		top: 50%;
		transform: translateY(-50%);
		border: none;
	}
	.van-search__content{
		background: #fff;
	}
</style>
