import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'
Vue.use(less)
Vue.config.productionTip = false
// 重置css样式
import './assets/css/reset.css'
//自适应↓
import './assets/js/flexible.min.js'
import VideoPlayer from 'vue-video-player'
Vue.use(VideoPlayer)
require('vue-video-player/src/custom-theme.css')

import http from './api/http.js'
import apis from './api/api.js'
Vue.prototype.$http = http
Vue.prototype.$apis = apis
Vue.prototype.$Notify = Notify

// 导入vant
import Vant from 'vant';
import { Notify } from 'vant';
import 'vant/lib/index.css'
// 使用vant
Vue.use(Vant);
import 'animate.css'

// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'Y58f7kBjBmDgTIIxDy3p81W51Z6yBid3'
})

// 语言更换
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
