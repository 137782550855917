<template>
	<div id="foot">
	<!-- 页脚 -->
		<div class="foot-t">
			<div class="foot-t-l">
				<img :src='contactList.steamLogo' >
				<p>地址：{{contactList.steamAddress}}</p>
				<p>电话：<span>{{contactList.steamHotline}}</span></p>
				<p>传真：{{contactList.steamFax}}</p>
				<p>邮编：{{contactList.steamPostcode}}</p>
				<p>网址：{{contactList.steamUrl}}</p>
				<p>邮箱：{{contactList.steamEmail}}</p>
			</div>
			<div class="foot-t-r">
				<img src="../assets/images/erweima.png" >
				<p>关注瑞科阀门</p>	
			</div>
		</div>
		<div class="foot-b">
			<p>Copyright © 2011-2021 开封瑞科阀门有限公司  All rights reserved
豫ICP备14017486号-1</p>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				contactList:[],
			}
		},
		created() {
			
		},
		mounted() {
			this.getContact();// 获取脚部联系方式
		},
		methods:{
			getContact(){
				this.$apis.get(this.$apis.banner).then(res=>{
					console.log(res,"底部信息")
					this.contactList = res.data.data
				})
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	#foot{
		width: 100%;
		background-color: #242424;
		// 上半部分
		.foot-t{
			display: flex;
			justify-content: space-between;	
			align-items: flex-end;
			padding: 0 0.3rem;
			.foot-t-l{
				padding-top: 0.8rem;
				img{
					width: 1.94rem;
					height: auto;
				}
				p{
					font-size: 0.24rem;
					color: #a9a9a9;
					line-height: 0.5rem;
					span{
						font-size: 0.48rem;
						vertical-align: middle;
					}
				}
			}
			.foot-t-r{
				img{
					width: 1.5rem;
					height: 1.5rem;
				}
				p{
					font-size: 0.24rem;
					color: #a9a9a9;
				}
			}
		}
		// 下半部分
		.foot-b{
			width: 100%;
			height: 1rem;
			padding: 0 0.8rem;
			color: #666;
			font-size: 0.18rem;
			text-align: center;
			line-height: 0.36rem;
			padding-top: 0.15rem;
			border-top: 1px solid #3a3a3a;
		}
	}
</style>
